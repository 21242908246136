import axios from "axios";

const BASE_URL = 'https://qyzmetke.com/api/v1';

function getLocalAccessToken() {
    return localStorage.getItem("tokenAccess");
}

function getLocalRefreshToken() {
    return localStorage.getItem("tokenRefresh");
}

const onRequest = (config) => {
    const token = getLocalAccessToken();
    config.headers["Authorization"] = `${token ? `Bearer ` + token : ''}`;

    return config;
};

const onRequestError = (error) => {
    return Promise.reject(error);
};

const onResponse = (response) => {
    return response;
};

const onResponseError = async (error) => {
    if (error.response) {
        // Access Token was expired
        if (error.response.status === 401) {
            try {
                console.log(error);
                const rs = await axios.post(`${BASE_URL}/token/refresh`, {
                    refresh: getLocalRefreshToken(),
                });
                localStorage.setItem("tokenAccess", rs.data.data.access);
                error.config._retry = true;
                return axiosInstance(error.config);
            } catch (_error) {
                localStorage.removeItem('tokenAccess');
                localStorage.removeItem('tokenRefresh');
                localStorage.removeItem('ratee');
                window.location.replace('/');
                return Promise.reject(_error);
            }
        }
    }
    return Promise.reject(error);
};

export const setupInterceptorsTo = (
    axiosInstance ) => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}

const axiosInstance = setupInterceptorsTo(
    axios.create({
        baseURL: BASE_URL,
        headers: {

        },
    })
);

export default axiosInstance;
