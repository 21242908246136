import React, {useEffect, useState} from "react";
import axios from "../../../../../api";
import ProfileModal from "./Modal";

const Profile = (props) => {
    const {disabled, profile, setProfile, getTranslate, webV, language, FIRST_NAME, LAST_NAME} = props;
    const rateeId = profile?.ratee;
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState(profile?.LAST_NAME + ' ' + profile.FIRST_NAME);
    const [form, setForm] = useState();
    const saveProfileChangesHandler = async () => {
        setIsLoading(true);
        await axios.put(`ratees/${rateeId}`, form).then((r) => {
            setIsLoading(false);
            setIsVisible(false);
        }).catch(e => {
            setIsLoading(false);
            if(e?.response?.data?.code === 17) {
                return setError(getTranslate('errorActiveCampaignEdit'))
            }
            setError(getTranslate('errorName'));
        });
        profile[LAST_NAME] !== undefined && profile[FIRST_NAME] !== undefined &&
        setTitle(profile[LAST_NAME] + ' ' + profile[FIRST_NAME]);
    }
    const onChangeHandler = (e) => {
        setError(null);
        const name = e.target.name;
        const value = e.target.value;
        setProfile({...profile, [name]: value});
        setForm({...form, [name]: value});
        if(name === 'pref_lang') {
            localStorage.setItem('pref_lang', value)
        }
    }
    useEffect(() => {
        profile[LAST_NAME] !== '' || profile[FIRST_NAME] !== '' ? setTitle(profile[LAST_NAME] + ' ' + profile[FIRST_NAME]) :
            setTitle(getTranslate('placeholderFullName'))
    }, [language])

    const checkBoxHandle = (boolean) => {
        setProfile((prevState) => {
            const newProfile = JSON.parse(JSON.stringify(prevState));
            const marker = newProfile.equity_bias_marker_type;
            marker[0].value = boolean;
            return newProfile;
        });
    }
    useEffect(() => {
        setForm({...form, ['equity_bias_marker_type']: profile.equity_bias_marker_type})
    }, [profile.equity_bias_marker_type])
    return (
        <>
            <div>
                <button disabled={disabled} onClick={() => setIsVisible(!isVisible)}
                        className={`form-control text-start ${!webV ? "w-auto" : " mt-3"}`}>{title}</button>
            </div>
            {isVisible && <ProfileModal
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                getTranslate={getTranslate}
                onChangeHandler={onChangeHandler}
                profile={profile}
                checkBoxHandle={checkBoxHandle}
                saveProfileChangesHandler={saveProfileChangesHandler}
                isLoading={isLoading}
                error={error}
                LAST_NAME={LAST_NAME}
                FIRST_NAME={FIRST_NAME}
            />}
        </>
    )
}

export default Profile;