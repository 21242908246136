export const validate = (sheet, cell, valiDigit) => {
    const vocabulary = {
        A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26
    };
    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const calcSlot = (sheet, cell) => {
        let letterCumulativePosition = 0;
        const maxSheets = 10;
        const sheetNo = Number(sheet.toString().replace(/^\D+/g, ''));
        if (!sheetNo || sheetNo > maxSheets || sheetNo < 1) return 'error: wrong sheet input';
        const prevSheetCumulative = (sheetNo - 1) * 1000000;
        const letterMaxRows = 10000;
        const vocabularySize = 26;
        const letters = cell.replace(/[^A-Za-z]/g, '');
        const number = Number(cell.replace(/^\D+/g, ''));
        const hasUnconditionals = cell.length > (letters + number).length;
        if (hasUnconditionals) return 'errorValidate3';
        if (number < 1) return 'errorValidate4';
        if (number > letterMaxRows) return 'errorValidate5';
        const lettersArr = letters.split('');
        const maxLettersLen = 2;
        const lettersLen = letters.length;
        if (lettersLen > maxLettersLen) return 'errorValidate6';
        if (lettersLen === maxLettersLen && lettersArr[0] > 'C' || lettersLen === maxLettersLen && lettersArr[0] === 'C' && lettersArr[1] > 'V') return 'error: sheet max column size exceeded 1';
        const multiple = vocabulary[lettersArr[0]];
        let lettersCumulative = 0;
        if (lettersLen < maxLettersLen) lettersCumulative = multiple * letterMaxRows;
        if (lettersLen === maxLettersLen) lettersCumulative = (multiple * vocabularySize + vocabulary[lettersArr[1]]) * letterMaxRows;
        letterCumulativePosition = lettersCumulative - letterMaxRows + number + prevSheetCumulative;
        return letterCumulativePosition;
    };
    const calcPrevHead = (extentTarget, targetIncHead) => {
        let targetPrevHead = 0;
        const prevExtentTarget = extentTarget - 1;
        const prevCardinalityTarget = 2 ** prevExtentTarget;
        const minIncPrev = prevCardinalityTarget - 1;
        const maxIncPrev = minIncPrev + prevCardinalityTarget;
        if ((targetIncHead - prevCardinalityTarget) >= minIncPrev && (targetIncHead - prevCardinalityTarget) < maxIncPrev) {
            targetPrevHead = targetIncHead - prevCardinalityTarget;
        } else {
            targetPrevHead = targetIncHead - prevCardinalityTarget - prevCardinalityTarget;
        }
        return targetPrevHead;
    };
    const calcExtentTarget = (targetIncHead) => {
        let extentTarget = 0;
        const log = Math.log2(targetIncHead);
        const mod = log % 1;
        const int = Math.floor(log);
        let extent = 0;
        if (mod > 0) {
            extent = int + 1;
        } else {
            extent = int;
        }
        const cardinality = 2 ** extent;
        const maxIncPrev = cardinality - 2;
        const minIncPrev = maxIncPrev / 2;
        if (targetIncHead === 1) {
            extentTarget = 1;
        } else if (targetIncHead >= minIncPrev && targetIncHead <= maxIncPrev) {
            extentTarget = extent - 1;
        } else {
            extentTarget = extent;
        }
        return extentTarget;
    };
    const calcForkSibling = (predecessorSlot, targetIncHead) => {
        let forkSibling = 0;
        const extentPredecessor = calcExtentTarget(predecessorSlot);
        const cardinalityTarget = 2 ** extentPredecessor;
        const odd = cardinalityTarget + predecessorSlot;
        const even = cardinalityTarget + odd;
        if (odd === targetIncHead) {
            forkSibling = even;
        } else {
            forkSibling = odd;
        }
        return forkSibling;
    };
    const calcValidationLineage = (slot) => {
        const validationLineage = {
            predecessorSlots: [],
            forkSiblings: []
        };
        let predecessorSlots = [];
        let forkSiblings = [];
        let targetIncHead = Number(slot);
        if (targetIncHead < 1) return 'errorValidate7';
        predecessorSlots.unshift(targetIncHead);
        let extentTarget = calcExtentTarget(targetIncHead);
        let predecessorSlot = 0;
        let forkSibling = 0;
        for (let i = 0; i < extentTarget;) {
            if (targetIncHead === 2) {
                forkSiblings.unshift(1);
                break;
            }
            if (targetIncHead === 1) {
                forkSiblings.unshift(2);
                break;
            }
            predecessorSlot = calcPrevHead(extentTarget, targetIncHead);
            forkSibling = calcForkSibling(predecessorSlot, targetIncHead);
            forkSiblings.unshift(forkSibling);
            if (predecessorSlot > 0) predecessorSlots.unshift(predecessorSlot); //here
            targetIncHead = predecessorSlot;
            extentTarget = calcExtentTarget(targetIncHead);
        }
        validationLineage.predecessorSlots = predecessorSlots;
        validationLineage.forkSiblings = forkSiblings;
        return validationLineage;
    };
    const calcSheetCell = (slot) => {
        let sheetCell = '';
        if (slot < 1) return 'errorValidate7';
        const maxSheets = 10;
        const letterMaxRows = 10000;
        const vocabularySize = 26;
        const sheetSize = 1000000;
        let sheet = 0;
        if (slot / sheetSize >= 1) sheet = Math.ceil(slot / sheetSize);
        if (slot / sheetSize < 1) sheet = 1;
        if (sheet > maxSheets) return 'errorValidate2';
        const singleSheetSlot = slot - (sheet - 1) * sheetSize;
        let column = 0;
        if (singleSheetSlot / letterMaxRows >= 1) column = Math.ceil(singleSheetSlot / letterMaxRows);
        if (singleSheetSlot / letterMaxRows < 1) column = 1;
        let letter = '';
        let firstLetter = '';
        let lastLetter = '';
        firstLetter = letters[Math.floor((column - 1) / vocabularySize) - 1];
        lastLetter = letters[(column - vocabularySize * Math.floor((column - 1) / vocabularySize)) - 1];
        if (!firstLetter) firstLetter = '';
        letter = firstLetter + lastLetter;
        const singleColumnSlot = singleSheetSlot - (column - 1) * letterMaxRows;
        sheetCell = 'Sheet' + sheet + ',' + letter + singleColumnSlot;
        return sheetCell;
    };
    const calcValidationLineageSheetCells = (validationLineage) => {
        const validationLineageSheetCells = {
            predecessorSheetCells: [],
            forkSiblingSheetCells: []
        };
        let predecessorSheetCells = [];
        let forkSiblingSheetCells = [];
        validationLineage.predecessorSlots.forEach(slot => {
            let sheetCell = calcSheetCell(slot);
            predecessorSheetCells.push(sheetCell);
        });
        validationLineage.forkSiblings.forEach(slot => {
            let sheetCell = calcSheetCell(slot);
            forkSiblingSheetCells.push(sheetCell);
        });
        validationLineageSheetCells.predecessorSheetCells = predecessorSheetCells;
        validationLineageSheetCells.forkSiblingSheetCells = forkSiblingSheetCells;
        return validationLineageSheetCells;
    };
    const slot = calcSlot(sheet, cell);
    const validationLineage = calcValidationLineage(slot);
    const validationLineageSheetCells = calcValidationLineageSheetCells(validationLineage)
    const binary = valiDigit.toString(2);
    const binaryString = binary.toString();
    const binaryArr = binaryString.split('');
    binaryArr.shift();
    if (validationLineageSheetCells.predecessorSheetCells.length != binaryArr.length) return 'errorValidate8';
    const fullValidationLineage = {
        predecessorSheetCells: validationLineageSheetCells.predecessorSheetCells,
        votes: binaryArr,
        forkSiblingSheetCells: validationLineageSheetCells.forkSiblingSheetCells
    }
    return fullValidationLineage;
}