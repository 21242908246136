import React, {useEffect, useState} from "react";
import {
    PopUpCloseBtn, PopUpContainer,
    PopUpContent,
    PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage
} from "../../../../Authentication/AuthForm/styles";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";

const ProfileModal = (props) => {
    const {isVisible, setIsVisible, getTranslate, language, onChangeHandler, checkBoxHandle, saveProfileChangesHandler,
        profile, isLoading, error, LAST_NAME, FIRST_NAME} = props;
    const [placeHolders, setPlaceHolders] = useState({});
    const webV = window.innerWidth > 1024;
    useEffect(() => {
        setPlaceHolders({
            first_name: profile[FIRST_NAME] !== '' ? profile[FIRST_NAME] : null,
            last_name: profile[LAST_NAME] !== '' ? profile[LAST_NAME] : null,
            email: profile?.email !== '' ? profile?.email : null,
            telegram: profile?.telegram_name !== '' ? profile?.telegram_name : null,
        })
    }, [language, LAST_NAME, FIRST_NAME]);

    return(
        <PopUpContainer onClick={() => setIsVisible(!isVisible)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <span style={{opacity: 0.8, color: 'red'}}>{error}</span>
                    <PopUpCloseBtn onClick={() => setIsVisible(!isVisible)}/>
                </PopUpHeader>
                <PopUpContent>
                    <label className="mt-2 mb-1">{getTranslate('lastName')}</label>
                    <input onChange={onChangeHandler} className="form-control mt-2"
                           defaultValue={placeHolders.first_name} type="text"
                           name={FIRST_NAME} placeholder={getTranslate('lastName')}/>
                    <label className="mt-2 mb-1">{getTranslate('firstName')}</label>
                    <input onChange={onChangeHandler} className="form-control mt-2"
                           defaultValue={placeHolders.last_name} type="text"
                           name={LAST_NAME} placeholder={getTranslate('firstName')}/>
                    <label className="mt-2 mb-1">{getTranslate('email')}</label>
                    <input onChange={onChangeHandler} required className="form-control mt-2"
                           defaultValue={placeHolders.email} type="text"
                           name="email" placeholder={getTranslate('email')}/>
                    <label className="mt-2 mb-1">{getTranslate('telegram')}</label>
                    <input onChange={onChangeHandler} className="form-control mt-2"
                           defaultValue={placeHolders.telegram} type="text"
                           name="telegram_name" placeholder={getTranslate('telegram')}/>
                    <div className="d-flex mt-1">
                        <span style={!webV ? {fontSize: '12px'} : {}} className="my-auto">{getTranslate('prefLang')}</span>
                        <select name="pref_lang" defaultValue={profile?.pref_lang}
                                className="mt-2 ms-2 w-auto form-select" onChange={onChangeHandler} id="">
                            <option value="RU">RU</option>
                            <option value="QZ">QZ</option>
                            <option value="EN">EN</option>
                        </select>
                    </div>
                    <div className="mt-3">
                        <Form.Check
                            style={{overflow: 'inherit'}}
                            checked={!profile?.equity_bias_marker_type[0].value}
                            inline
                            label={getTranslate('male')}
                            name="gender"
                            type="radio"
                            id={`inline-radio-1`}
                            onChange={() => checkBoxHandle(false)}
                        />
                        <Form.Check
                            style={{overflow: 'inherit'}}
                            checked={profile?.equity_bias_marker_type[0].value}
                            inline
                            label={getTranslate('female')}
                            name="gender"
                            type="radio"
                            id={`inline-radio-1`}
                            onChange={() => checkBoxHandle(true)}
                        />
                    </div>
                </PopUpContent>
                <PopUpFooterWrapper>
                    <Button onClick={saveProfileChangesHandler} className="ms-auto" variant="success">
                        {isLoading ?
                            <span className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                            : getTranslate('save')
                        }
                    </Button>
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>
    )
}

export default ProfileModal;