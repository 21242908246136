import React, {useState} from "react";
import {Carousel} from "react-bootstrap";
import CarouseItem from "./CarouseItem";
import ConfirmModal from "../Cabinet/OwnerCabinet/Modals/ConfirmModal";
import {TELEGRAM_BOT_RATEE} from "../../bot";

const Voting = (props) => {
    const mobV = window.innerWidth < 1024;
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    return (
        <>
            <div className="voting">
                <Carousel style={{height: '100vh'}} variant="dark">
                    {props.campaigns.length > 0 ? props.campaigns.map(c => (
                            <Carousel.Item key={c?.campaign?.campaign_id}>
                                <CarouseItem getTranslate={props.getTranslate} mobV={mobV} campaign={c}/>
                            </Carousel.Item>
                        ))
                        :
                        <a className="no-campaign" onClick={() => setShowConfirmModal(true)} style={{margin: 'auto'}}>
                            {props.getTranslate('becomeCandidate')}</a>
                    }
                </Carousel>
            </div>
            {showConfirmModal && <ConfirmModal becomeCandidate={true} href={TELEGRAM_BOT_RATEE}
                                               content={props.getTranslate('campaignCreateConfirmText')}
                                               getTranslate={props.getTranslate} close={setShowConfirmModal}/>}
        </>
    );
};

export default Voting;