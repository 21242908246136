import React, {useState} from "react";
import {
    PopUpCloseBtn, PopUpContainer,
    PopUpContent,
    PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage,
    PopUpTitle
} from "../../Authentication/AuthForm/styles";
import {Button} from "react-bootstrap";
import axios from "../../../api";

const CommentForm = (props) => {
    const [value, setValue] = useState('');
    const {comment, getTranslate, close, id} = props;
    const [isLoading, setIsLoading] = useState(false);
    const fetchComment = async () => {
        const form = new FormData();
        form.append('comment', value);
        setIsLoading(true);
        await axios.put(`ratees/${id}`, form).then(() => {
            close(false);
            setIsLoading(false)
        }).catch(e => {
            console.error(e);
            setIsLoading(false);
        })
    }

    return(
        <PopUpContainer onClick={() => close(false)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <PopUpCloseBtn onClick={() => close(false)}/>
                </PopUpHeader>
                <PopUpContent>
                   <div><h3>{getTranslate('comment')}</h3></div>
                    <div><textarea onChange={(e) => setValue(e.target.value)}
                                   defaultValue={comment} className="w-100 p-2" ></textarea></div>
                </PopUpContent>
                <PopUpFooterWrapper>
                    <Button onClick={fetchComment} className="ms-auto" variant="success">
                        {isLoading ?
                            <span className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                            : getTranslate('save')
                        }
                    </Button>
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>

    )
}

export default CommentForm;