import React, {useEffect, useState} from "react";
import axios from "../../../../../api";
import BenefitsModal from "./Modal";

const Benefits = (props) => {
    const {profile, variant, getTranslate, setProfile} = props;
    const [form, setForm] = useState([])
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const formData = {[variant]: []};
    const [index, setIndex] = useState(0);
    const placeHolderTitle = () => {
        return variant === "cv_links" ? getTranslate('placeholderResume') : variant === 'pr_links' ?
            getTranslate('placeholderMedia') : variant === "endorsements" ? getTranslate('placeholderEndorsements') : null;
    }
    const title = variant === 'cv_links' ? getTranslate('placeholderExperience') : variant === "pr_links" ?
        getTranslate('placeholderPR') : variant === "endorsements" ? getTranslate('placeholderEndor') : null;
    const saveCampaignChangesHandler = async () => {
        form.map(item => {
            if (item.title !== '') {
                formData[variant].push(item.title)
            }
        });
        await axios.put(`ratees/${profile?.ratee}`, formData).then((r) => {
            setIsVisible(false);
            setProfile((prevState) => {
                let newProfile = JSON.parse(JSON.stringify(prevState));
                newProfile[variant] = r.data.data[variant];
                return newProfile;
            });
        });
    }
    useEffect(() => {
        return () => {
            for (let index = 0; index < profile?.[variant].length; index++) {
                form.push({id: index, title: profile?.[variant][index]})
            }
            setIndex(form.length)
        };
    }, []);
    const addInput = () => {
        setError(null);
        if (form.length < 5) {
            setForm([...form, {id: index, title: ''}]);
            setIndex(prevState => prevState + 1);
        } else {
            setError(getTranslate('errorFiveLink'))
        }
    }
    const onChangeHandler = (e, id) => {
        setError(null);
        form.filter(item => {
            if (item.id === id) {
                item.title = e.target.value;
            }
        })
    }
    const removeInputHandler = (e, id) => {
        if (form.length > 1) {
            setError(null);
            setForm(form.filter(item => item.id !== id));
        } else {
            setError(getTranslate('errorMinimalOneLink'))
        }
    }

    return (
        <>
            <div>
                <button onClick={() => setIsVisible(!isVisible)}
                        className="form-control text-start mt-2">{title}</button>
            </div>
            {isVisible && <BenefitsModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                form={form}
                getTranslate={getTranslate}
                variant={variant}
                onChangeHandler={onChangeHandler}
                placeHolderTitle={placeHolderTitle}
                removeInputHandler={removeInputHandler}
                addInput={addInput}
                saveCampaignChangesHandler={saveCampaignChangesHandler}
                isLoading={isLoading}
                error={error}
            />}
        </>
    )
}

export default Benefits;