export const checkAuthForm = (auth, setError, setMessage) => {
    if(auth.phone === ''){
        setError(true);
        setMessage('Введите номер телефона')
        return false;
    }
    if(auth.password === ''){
        setError(true);
        setMessage('Введите Ваш пароль')
        return false;
    }
    if(!auth.phone.includes('+')) {
        setError(true);
        setMessage('Формат ввода номера +7хххххххххх')
        return false;
    }
    if(auth.phone.length < 12){
        setError(true);
        setMessage('Номер должен содержать 12 символов')
        return false;
    }
    if(auth.password.length < 6){
        setError(true);
        setMessage('Пароль должен содержать 6 символов')
        return false;
    }
    return true;
}