import React from "react";
import {
    PopUpCloseBtn, PopUpContainer,
    PopUpContent,
    PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage
} from "../../../Authentication/AuthForm/styles";
import axios from "../../../../api";

const AdminVerifyModal = (props) => {
    const {campaign_id, close, getTranslate, setCampaignVerified} = props;
    const verifyCampaign = async () => {
        await axios.put(`/campaigns/${campaign_id}/verified`, {"verified": true}).then((r) => {
            close(false);
            setCampaignVerified(true);
        })
    }

    return (
        <PopUpContainer onClick={() => close(false)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <PopUpCloseBtn onClick={() => close(false)}/>
                </PopUpHeader>
                <PopUpContent>
                    <b>{getTranslate('confirmModal')}</b>
                </PopUpContent>
                <PopUpFooterWrapper>
                    <button onClick={verifyCampaign}
                            className="btn btn-success ms-auto my-4">{getTranslate('confirmButton')}</button>
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>
    )
}

export default AdminVerifyModal;