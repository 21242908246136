import initialState from "../initialState";
import {createSlice} from "@reduxjs/toolkit";

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setUserProfile(state, action){
            state.user = action.payload;
        },
        setIsAdmin(state, action){
            state.isAdmin = action.payload;
        },
    }
})

export const {setUserProfile, setIsAdmin} = mainSlice.actions;

export default mainSlice.reducer
