import './App.scss';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import MainPage from "./pages/MainPage";
import Cabinet from "./pages/Cabinet";
import {Navigation} from "./components/Navigation";
import PageNotFound from "./pages/404";
import Campaigns from "./pages/Campaigns";
import Rankings from "./pages/Rankings";
import {translate} from "./language";
import ServiceTerms from "./pages/ServiceTerms";
import ValidatePage from "./pages/Validate";
import ContactsPage from "./pages/Contacts";
import FAQPage from "./pages/FAQ";
import AboutPage from "./pages/About";
import * as ReactGA from "react-ga";

function App() {
    const TRACKING_ID = "G-WG5K3F58VL";
    ReactGA.initialize(TRACKING_ID);

    const [isAdmin, setIsAdmin] = useState(false);
    const [language, setLanguage] = useState('');

    const getTranslate = (value) => {
        return language === "QZ" ? translate[value][2] : language === "EN" ? translate[value][1] : translate[value][0];
    }

    const changeLanguage = (value) => {
        setLanguage(value);
        localStorage.setItem('pref_lang', value);
    }

    useEffect(() => {
        if(!localStorage.getItem('pref_lang')) localStorage.setItem('pref_lang', 'RU');
        setLanguage(localStorage.getItem('pref_lang'));
    }, [localStorage.getItem('pref_lang')]);

    useEffect(() => {
        const roles = localStorage.getItem('roles');
        if(roles) roles.includes('ADMIN') && setIsAdmin(true);
    }, [localStorage.getItem('roles')]);

    return (
        <Router>
            <Navigation getTranslate={getTranslate} language={language} setLanguage={changeLanguage} isAdmin={isAdmin}/>
            <Routes>
                <Route path="/">
                    <Route index element={<MainPage getTranslate={getTranslate}/>}/>
                    <Route path="profiles/:id" element={<Cabinet language={language} getTranslate={getTranslate} isAdmin={isAdmin}/>}/>
                </Route>
                <Route path="/campaigns" element={<Campaigns getTranslate={getTranslate} isAdmin={isAdmin}/>}/>
                <Route path="/rankings" element={<Rankings getTranslate={getTranslate} isAdmin={isAdmin}/>}/>
                <Route path="/validate" element={<ValidatePage getTranslate={getTranslate} isAdmin={isAdmin}/>}/>
                <Route path="/service-terms" element={<ServiceTerms language={language} getTranslate={getTranslate} isAdmin={isAdmin}/>}/>
                <Route path="/about" element={<AboutPage language={language} getTranslate={getTranslate}/>}/>
                <Route path="/faq" element={<FAQPage language={language} getTranslate={getTranslate}/>}/>
                <Route path="/contacts" element={<ContactsPage language={language} getTranslate={getTranslate}/>}/>
                <Route path="*" element={<PageNotFound getTranslate={getTranslate}/>}/>
            </Routes>
        </Router>
    );
}

export default App;
