import React from "react";
import no_avatar from '../../assets/img/avatar.png'
import qwe from '../../assets/img/qweqwe.jpg'
import ewq from '../../assets/img/ewq.jpg'
import button from '../../assets/img/accordion-button.svg'

const Ranking = (props) => {
    const {campaign, webV, index, getTranslate} = props;
    const id = campaign.campaign.ratee;

    return (
        <>
            {webV ?
                <div className="campaign" onClick={() => window.location.replace(`/profiles/${id}`)}>
                    <div className="campaign-left">
                        <div className="index"><h3>{index}</h3></div>
                        <div className="campaign-left-image">
                            <img src={campaign?.ratee?.image || no_avatar} alt="avatar"/>
                        </div>
                        <div className="campaign-left-info">
                            <b>{campaign?.seek_position?.title_ru}</b>
                            <b>{campaign?.ratee?.first_name_ru + ' ' + campaign?.ratee?.last_name_ru}</b>
                            <p>{getTranslate('outOf')}
                                <b> {campaign?.campaign?.number_of_slots}</b> {getTranslate('votes')}
                                <b> {campaign?.campaign?.ratee_score}</b> {getTranslate('forTheCandidate')}</p>
                        </div>
                    </div>
                    <div className="campaign-right">
                        <a href={"/profiles/" + id}
                           style={{background: 'rgb(96, 141, 253)', padding: '10px 28px', textTransform: 'none'}}
                           className="btn btn-custom btn-lg">{getTranslate('more')}</a>
                    </div>
                </div>
                :
                <div className="campaign" onClick={() => window.location.replace(`/profiles/${id}`)}>
                    <div className="campaign-left">
                        <div className="index"><h3>{index}</h3></div>
                        <div className="campaign-left-image">
                            <img src={campaign?.ratee?.image || no_avatar} alt="avatar"/>
                        </div>
                        <div className="campaign-mob-bio">
                            <b>{campaign?.seek_position?.title_ru}</b>
                            <div>
                                <b>{campaign?.ratee?.first_name_ru + ' ' + campaign?.ratee?.last_name_ru}</b>
                            </div>
                            <p>{getTranslate('outOf')}
                                <span> {campaign?.campaign?.number_of_slots}</span> {getTranslate('votes')}
                                <span> {campaign?.campaign?.ratee_score}</span> {getTranslate('forTheCandidate')}</p>
                        </div>
                    </div>

                    <div className="campaign-right">
                        <a href={"/profiles/" + id} className=""><img
                            src={button}
                            alt="button"/></a>
                    </div>
                </div>
            }

        </>
    )
}

export default Ranking;