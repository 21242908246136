import React, {useEffect} from "react";

const AboutPage = (props) => {
    const {getTranslate, language} = props;
    useEffect(() => {
        let msg = getTranslate('AboutPage');
        document.getElementById('msg-div').innerHTML = msg;
    }, [language])

    return(
        <div style={{marginTop: '100px', padding: '24px', whiteSpace: 'pre-wrap'}}>
            <div id="msg-div"></div>
        </div>
    )
}

export default AboutPage;