import React from "react";
import {
    PopUpCloseBtn, PopUpContainer,
    PopUpContent, PopUpFooterWrapper,
    PopUpHeader,
    PopUpRemoveBtn,
    PopUpStage
} from "../../../../Authentication/AuthForm/styles";
import {Button} from "react-bootstrap";

const BenefitsModal = (props) => {
    const {isVisible,
        setIsVisible,
        form,
        getTranslate,
        variant,
        onChangeHandler,
        placeHolderTitle,
        removeInputHandler,
        addInput,
        saveCampaignChangesHandler,
        isLoading, error} = props;

    return(
        <PopUpContainer onClick={() => setIsVisible(!isVisible)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <span style={{opacity: 0.8, color: 'red'}}>{error}</span>
                    <PopUpCloseBtn onClick={() => setIsVisible(!isVisible)}/>
                </PopUpHeader>
                <PopUpContent>
                    {form.map(item =>
                        <div className=" position-relative" key={item?.id}>
                            <label className="mt-2 mb-1">{getTranslate(
                                variant === "cv_links" ? 'experienceLinkTitle' : variant === 'pr_links' ?
                                    'prLinkTitle' : variant === "endorsements" ? 'endorLinkTitle' : null
                            )}</label>
                            <input onChange={(e) => onChangeHandler(e, item.id)} className="form-control mt-2"
                                   type="text" placeholder={placeHolderTitle()} defaultValue={item?.title}
                                   name="brief_ru"/>
                            <PopUpRemoveBtn onClick={(e) => removeInputHandler(e, item.id)}/>
                        </div>
                    )
                    }
                </PopUpContent>
                <PopUpFooterWrapper>
                    <Button onClick={addInput}>{getTranslate('addInput')}</Button>
                    <Button onClick={saveCampaignChangesHandler} variant="success">
                        {isLoading ?
                            <span className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                            : getTranslate('save')
                        }
                    </Button>
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>
    )
}

export default BenefitsModal;