import React, {useEffect, useState} from "react";
import Campaign from "../../components/Campaign";
import axios from "../../api";
import Loader from "../../components/Loader";

const Campaigns = (props) => {
    const {getTranslate ,isAdmin} = props;
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const [verified, setVerified] = useState(false);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const webV = window.innerWidth > 1024

    useEffect(() => {
        setIsLoading(true);
        const Promise = axios.post('campaigns/ongoing/?limit=10&offset=1', {is_admin: isAdmin}); //IS ADMIN
        Promise.then(response => {
            setCampaigns(response?.data?.data?.campaigns);
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }).catch(e => {
            console.error(e);
            setIsLoading(false);
        })
    }, [isAdmin])

    return (
        <div style={{marginTop: '100px'}}>
            {webV ?
                <div className="sub-header container ms-2">
                    <h3>{getTranslate('openCampaigns')}</h3>
                    <div className="w-50 position-relative">
                        <input placeholder={getTranslate('candidate').toLowerCase()} className="form-control w-100"
                               onChange={(e) => setSearch(e.target.value.toLowerCase().trim())} type="text"></input>
                    </div>
                    {isAdmin &&
                        <div>
                            <input type="checkbox" onChange={() => setVerified(!verified)}/>
                            <h3>{getTranslate('unverified')}</h3>
                        </div>
                    }
                </div>
                :
                <div>
                    <div className="d-flex container justify-content-around pb-4">
                        <b className="my-auto">{getTranslate('openCampaigns')}</b>
                        <i className="fa fa-search" onClick={() => setIsInputVisible(!isInputVisible)}></i>

                    </div>
                    {isAdmin &&
                        <div className="d-flex container px-4 pb-2">
                            <input style={{width: '20px'}} type="checkbox" onChange={() => setVerified(!verified)}/>
                            <p className="my-auto ms-2">{getTranslate('unverified')}</p>
                        </div>
                    }
                    {isInputVisible &&
                        <div className="container mt-3">
                            <input className="form-control" placeholder={getTranslate('candidate').toLowerCase()}
                                   onChange={(e) => setSearch(e.target.value.toLowerCase().trim())} type="text">
                            </input>
                        </div>
                    }
                </div>
            }

            <div className="container">
                {isLoading ? <Loader/>
                    :
                    campaigns && campaigns.length > 0 ?
                        campaigns
                            .filter(c =>
                                verified ? c?.campaign?.verified === false : c?.campaign
                            )
                            .filter(c => c?.ratee?.first_name_ru.toLowerCase().includes(search) || c?.ratee?.last_name_ru.toLowerCase().includes(search))
                            .map(campaign => <Campaign key={campaign.campaign.campaign_id} campaign={campaign}
                                                       webV={webV} getTranslate={getTranslate}/>)
                        :
                        <h4 className="container mt-5">{getTranslate('noActiveCampaigns2')}</h4>
                }

            </div>
        </div>
    )
}

export default Campaigns;