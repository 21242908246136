import React, {useEffect, useState} from "react";
import {
    PopUpCloseBtn, PopUpContainer,
    PopUpContent,
    PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage
} from "../../../../Authentication/AuthForm/styles";
import {Button} from "react-bootstrap";

const SeekModal = (props) => {
    const {
        isVisible,
        profile,
        setIsVisible,
        TITLE,
        onChangeHandler,
        saveCampaignChangesHandler,
        getTranslate,
        isLoading,
        language
    } = props;
    const [placeHolders, setPlaceHolders] = useState({});

    useEffect(() => {
        setPlaceHolders({
            title: profile?.seek_position[TITLE] !== '' ? profile?.seek_position[TITLE] : null,
            public_pages: profile?.seek_position?.public_pages !== '' ? profile?.seek_position?.public_pages : null,
        })
    }, [language, TITLE]);

    return (
        <PopUpContainer onClick={() => setIsVisible(!isVisible)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    {/*<span style={{opacity: 0.8, color: 'red'}}>{error}</span>*/}
                    <PopUpCloseBtn onClick={() => setIsVisible(!isVisible)}/>
                </PopUpHeader>
                <PopUpContent>
                    <label className="mt-2 mb-1">{getTranslate('agencyTitle')}</label>
                    <input onChange={onChangeHandler} className="form-control mt-2"
                           defaultValue={placeHolders.title} type="text"
                           placeholder={getTranslate('inputAgencyTitle')}
                           name={TITLE}/>
                    <label className="mt-2 mb-1">{getTranslate('linkTitle')}</label>
                    <input className="form-control mt-2" onChange={onChangeHandler}
                           defaultValue={placeHolders.public_pages} type="text"
                           placeholder={getTranslate('inputAgencyLink')}
                           name="public_pages"/>
                </PopUpContent>
                <PopUpFooterWrapper>
                    <Button onClick={saveCampaignChangesHandler} className="ms-auto" variant="success">
                        {isLoading ?
                            <span className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                            : getTranslate('save')
                        }
                    </Button>
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>
    )
}

export default SeekModal;