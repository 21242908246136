import React, {useEffect, useState} from "react";
import {
    PopUpCloseBtn, PopUpContainer,
    PopUpContent,
    PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage
} from "../../../../Authentication/AuthForm/styles";
import {Button} from "react-bootstrap";

const ProposalModal = (props) => {
    const {isVisible, setIsVisible, getTranslate, onChangeHandler, saveCampaignChangesHandler, language, profile,
        deleteProposalHandler, index, PROPOSALS_TITLE, PROMO_MESSAGE} = props;
    const [placeHolders, setPlaceHolders] = useState({});

    useEffect(() => {
        setPlaceHolders({
            brief: profile?.campaign[PROPOSALS_TITLE].brief?.length !== 0 ? profile?.campaign[PROPOSALS_TITLE].brief[index] : null,
            details: profile?.campaign[PROPOSALS_TITLE].details?.length !== 0 ? profile?.campaign[PROPOSALS_TITLE].details[index] : null,
            timeline: profile?.campaign[PROPOSALS_TITLE].timeline?.length !== 0 ? profile?.campaign[PROPOSALS_TITLE].timeline[index] : null,
            promo_message: profile?.campaign[PROMO_MESSAGE] !== '' ? profile?.campaign[PROMO_MESSAGE] : null,
        })
    }, [language, PROPOSALS_TITLE, PROMO_MESSAGE]);

    return(
        <PopUpContainer onClick={() => setIsVisible(!isVisible)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <PopUpCloseBtn onClick={() => setIsVisible(!isVisible)}/>
                </PopUpHeader>
                <PopUpContent>
                    <label className="mt-2 mb-1">{getTranslate('briefTitle')}</label>
                    <input onChange={onChangeHandler} className="form-control mt-2"
                           defaultValue={placeHolders.brief} type="text" placeholder={getTranslate('inputBrief')}
                           name="brief"/>
                    <label className="mt-2 mb-1">{getTranslate('durationTime')}</label>
                    <input className="form-control mt-2" onChange={onChangeHandler}
                           defaultValue={placeHolders.timeline} type="text"
                           placeholder={getTranslate('inputTimeLines')}
                           name="timeline"/>
                    <label className="mt-2 mb-1">{getTranslate('detailsTitle')}</label>
                    <textarea onChange={onChangeHandler} className="form-control mt-2"
                              placeholder={getTranslate('inputDetails')}
                              defaultValue={placeHolders.details}
                              name="details"/>
                    <label className="mt-2 mb-1">{getTranslate('promoMessageTitle')}</label>
                    <input onChange={onChangeHandler} className="form-control mt-2"
                           placeholder={getTranslate('inputPromoMessage')}
                           defaultValue={placeHolders.promo_message} type="text"
                           name={PROMO_MESSAGE}/>
                </PopUpContent>
                <PopUpFooterWrapper>
                    <Button onClick={() => deleteProposalHandler(index)} variant="danger">{getTranslate('delete')}</Button>
                    <Button onClick={saveCampaignChangesHandler} variant="success">{getTranslate('save')}</Button>
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>
    )
}

export default ProposalModal;