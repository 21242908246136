import React, {useEffect, useState} from "react";
import axios from "../../../../../api";
import ProposalModal from "./Modal";

const Proposal = (props) => {
    const {disabled, profile, setProfile, i, getTranslate, language, PROPOSALS_TITLE, PROMO_MESSAGE} = props;
    const campaignId = profile?.campaign?.campaign_id;
    const [isVisible, setIsVisible] = useState(false);
    const [title, setTitle] = useState(profile?.campaign[PROPOSALS_TITLE]?.brief?.length !== 0 &&
        profile?.campaign[PROPOSALS_TITLE]?.brief[i] !== '' ?
        profile?.campaign[PROPOSALS_TITLE]?.brief[i] : getTranslate('inputProposalFirst'));
    const [form, setForm] = useState({});
    const placeHolderTitle = i === 0 ? getTranslate('inputProposalFirst') : i === 1 ? getTranslate('inputProposalSecond')
        : i === 2 ? getTranslate('inputProposalThird') : null;
    const saveCampaignChangesHandler = async () => {
        await axios.put(`campaigns/${campaignId}`, form).then(() => setIsVisible(false));
        profile?.campaign[PROPOSALS_TITLE]?.brief[i] !== '' && profile?.campaign[PROPOSALS_TITLE]?.brief[i] !== undefined &&
        setTitle(profile?.campaign[PROPOSALS_TITLE]?.brief[i]);
    }
    const deleteProposalHandler = async (i) => {
        const form = JSON.parse(JSON.stringify(profile.campaign))
        form[PROPOSALS_TITLE].brief.splice(i, 1);
        form[PROPOSALS_TITLE].timeline.splice(i, 1);
        form[PROPOSALS_TITLE].details.splice(i, 1);
        setProfile((prevState) => {
            let newProfile = JSON.parse(JSON.stringify(prevState));
            newProfile.campaign = form;
            return newProfile;
        });
        await axios.put(`campaigns/${campaignId}`, form).then((res) => {
            setProfile((prevState) => {
                let newProfile = JSON.parse(JSON.stringify(prevState));
                newProfile.campaign = res.data.data.campaign;
                return newProfile;
            })
            setTitle(placeHolderTitle);
            setIsVisible(false);
        });
    }
    const onChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (['brief', 'details', 'timeline'].includes(name)) {
            setProfile((prevState) => {
                let newProfile = JSON.parse(JSON.stringify(prevState));
                newProfile.campaign[PROPOSALS_TITLE][name][i] = value;
                return newProfile;
            });
            setForm(() => {
                let newProfile = JSON.parse(JSON.stringify({...profile.campaign}));
                newProfile[PROPOSALS_TITLE][name][i] = value;
                return newProfile;
            })
        } else {
            setProfile((prevState) => {
                let newProfile = JSON.parse(JSON.stringify(prevState));
                newProfile.campaign[PROMO_MESSAGE] = value;
                return newProfile;
            });
            setForm(() => {
                let newProfile = JSON.parse(JSON.stringify({...profile.campaign}));
                newProfile[PROMO_MESSAGE] = value;
                return newProfile;
            })
        }
    };
    useEffect(() => {
        profile?.campaign[PROPOSALS_TITLE]?.brief && profile?.campaign[PROPOSALS_TITLE]?.brief.length !== 0 &&
        profile?.campaign[PROPOSALS_TITLE]?.brief[i] !== undefined && profile?.campaign[PROPOSALS_TITLE]?.brief[i] !== ''
        && profile?.campaign[PROPOSALS_TITLE]?.brief[i] !== null
            ? setTitle(profile?.campaign[PROPOSALS_TITLE]?.brief[i])
            : setTitle(placeHolderTitle);
    }, [language])

    return (
        <>
            <div>
                <button disabled={disabled} onClick={() => setIsVisible(!isVisible)}
                        className="form-control text-start mt-2">{title}</button>
            </div>
            {isVisible && <ProposalModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                getTranslate={getTranslate}
                onChangeHandler={onChangeHandler}
                saveCampaignChangesHandler={saveCampaignChangesHandler}
                deleteProposalHandler={deleteProposalHandler}
                language={language}
                profile={profile}
                index={i}
                PROPOSALS_TITLE={PROPOSALS_TITLE}
                PROMO_MESSAGE={PROMO_MESSAGE}
            />}
        </>
    )
}

export default Proposal;