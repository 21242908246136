import React from "react";

const ServiceTerms = (props) => {
    const {getTranslate, isAdmin} = props;

    return (
        <div style={{marginTop: '100px', padding: '24px' ,whiteSpace: 'pre-wrap'}}>
            <h3>{getTranslate('useTerms')}</h3>
            <p>
                {getTranslate('UseTermsPage')}
            </p>
        </div>
    )
}

export default ServiceTerms;