import React, {useCallback, useState} from "react";
import AuthForm from "./AuthForm";
import {useDispatch} from "react-redux";
import {fetchAuth} from "../../store/actions";
import {checkAuthForm} from "./helper";

const Authentication = (props) => {
    const dispatch = useDispatch();
    const [auth, setAuth] = useState({phone: '', password: ''});
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const handleError = useCallback((value) => {
            setError(value)
        }, []
    )
    const handleChange = (e) => {
        const {name, value} = e.target;
        setMessage('')
        setError(false);
        setAuth({...auth, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!checkAuthForm(auth, setError, setMessage)) return;
        dispatch(fetchAuth(auth)).then(response => {
            if (response) {
                localStorage.setItem('roles', response.roles);
                localStorage.setItem('pref_lang', response.pref_lang);
                window.location.replace(`/profiles/${response.ratee}`)
            } else {
                setError(true);
                setMessage('Неверный номер или пароль')
            }
        });
    }

    return (
        <AuthForm
            close={props.close}
            handleError={handleError}
            error={error}
            message={message}
            submit={handleSubmit}
            change={handleChange}
            getTranslate={props.getTranslate}
        />
    )
};

export default Authentication;