import React from "react";
import no_avatar from '../../assets/img/avatar.png'
import qwe from '../../assets/img/qweqwe.jpg'
import ewq from '../../assets/img/ewq.jpg'
import button from '../../assets/img/accordion-button.svg'
import moment from "moment";

const Campaign = (props) => {
    const {campaign, webV, getTranslate} = props;
    const id = campaign.campaign.ratee;
    return (
        <>
            {webV ?
                <div className="campaign" onClick={() => window.location.replace(`/profiles/${id}`)}>
                    <div className="campaign-left">
                        <div className="campaign-left-image">
                            <img src={campaign?.ratee?.image || no_avatar} alt="avatar"/>
                        </div>
                        <div className="campaign-left-info">
                            <b>{campaign?.seek_position?.title_ru}</b>
                            <b>{campaign?.ratee?.first_name_ru + ' ' + campaign?.ratee?.last_name_ru}</b>
                            <p>{getTranslate('deadline')} {moment(campaign?.campaign?.end_date).format('DD.MM.YYYY')}</p>
                        </div>
                    </div>

                    <div className="campaign-right">
                        <a href={"/profiles/" + id} style={{background: 'rgb(96, 141, 253)', padding: '10px 28px', textTransform: 'none'}}
                           className="btn btn-custom btn-lg">{getTranslate('more')}</a>
                    </div>
                </div>
                :
                <div className="campaign" onClick={() => window.location.replace(`/profiles/${id}`)}>
                    <div className="campaign-mob">
                        <div className="campaign-mob-image">
                            <img src={campaign?.ratee?.image || no_avatar} alt="avatar"/>
                        </div>
                        <div className="campaign-mob-bio ms-3">
                            <div>
                                <b>{campaign?.seek_position?.title_ru}</b>
                            </div>
                            <div>
                                <b>{campaign?.ratee?.first_name_ru + ' ' + campaign?.ratee?.last_name_ru}</b>
                            </div>
                            <p>{getTranslate('deadline')} {moment(campaign?.campaign?.end_date).format('DD.MM.YYYY')}</p>
                        </div>
                    </div>

                    <div className="campaign-right">
                        <a href={"/profiles/" + id} className=""><img
                            src={button}
                            alt="button"/></a>
                    </div>
                </div>
            }

        </>
    )
}

export default Campaign;