import React, {useState} from "react";
import {
    PopUpCloseBtn,
    PopUpContainer,
    PopUpContent, PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage
} from "../../../Authentication/AuthForm/styles";
import checkbox from '../../../../assets/img/check-box.svg'

const ProposalModal = (props) => {
    const {content, index, mobV, getTranslate} = props;
    const [isVisible, setIsVisible] = useState(false);
    return (
        <>
            <div className="proposal-guest">
                <div>
                    <img src={checkbox} alt="checkbox"/>
                <p className={mobV ? 'mob-text' : ''} onClick={() => setIsVisible(!isVisible)} >{content?.brief[index]}</p>
                </div>
            </div>
            {isVisible && <PopUpContainer onClick={() => setIsVisible(!isVisible)}>
                <PopUpStage onClick={(e) => e.stopPropagation()}>
                    <PopUpHeader >
                        <div>
                            <p style={{margin: 0}}>{getTranslate('durationTime')}</p>
                            <span style={{opacity: 0.75}}>{content?.timeline[index]}</span>
                            <hr className="mt-3" style={{width: '100%'}}/>
                            <div className="mt-3">
                                <span style={{fontWeight: "bolder"}}>{content?.brief[index]}</span>
                            </div>
                        </div>
                    </PopUpHeader>
                    <PopUpContent>
                        <hr style={{width: '100%', marginTop: 0}}/>
                        <p>{content?.details[index]}</p>
                    </PopUpContent>
                    <PopUpFooterWrapper>
                    </PopUpFooterWrapper>
                </PopUpStage>
            </PopUpContainer>}
        </>
    )
}

export default ProposalModal;