import React from "react";

const PageNotFound = (props) => {
    const {getTranslate} = props;
    return(
        <div className="page-not-found">
            <h1>404</h1>
            <h4>{getTranslate('404')}</h4>
            <a href="/">{getTranslate('takeToHomePage')}</a>
        </div>
    )
}

export default PageNotFound;