import React, {useLayoutEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from '../../api/index'
import Loader from "../../components/Loader";
import {useDispatch} from "react-redux";
import {setUserProfile} from "../../store/slices/mainSlice";
import CabinetComponent from "../../components/Cabinet";

const Cabinet = (props) => {
    const {getTranslate, language, isAdmin} = props;
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [candidate, setCandidate] = useState(null);
    const dispatch = useDispatch();

    useLayoutEffect(  () => {
        async function fetchData () {
            setIsLoading(true);
            await axios.get(`ratees/${id}`).then(r => {
                setCandidate(r.data.data);
                dispatch(setUserProfile({...r.data.data}));
            }).catch(e => {
                console.log(e?.response);
                setCandidate(null);
            });
        }
        fetchData().then(() => setIsLoading(false)).catch(() => setIsLoading(false));
    }, []);

    if(isLoading) {
        return (<Loader />)
    }

    return(
           <CabinetComponent isAdmin={isAdmin} getTranslate={getTranslate} candidate={candidate} language={language}/>

    )
}

export default Cabinet;