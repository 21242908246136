import React from "react";

const ValidateTable = (props) => {
    const {getTranslate, result} = props;
    let arr = [];
    let alternativeArr = [];
    for(let i = 0; i<31; i++){
        alternativeArr.push({id: i+1});
    }
    const createResultArr = () => {
        for(let i = 0; i < result?.predecessorSheetCells?.length; i++){
            arr.push({id: i+1, sheet: result.predecessorSheetCells[i], vote: result.votes[i], fork: result.forkSiblingSheetCells[i]})
        }
    }
    createResultArr();

    return (
        <table className="table table-striped ">
            <thead>
            <tr>
                <th scope="col">{getTranslate('validateDepth')}</th>
                <th scope="col">{getTranslate('validateSlot')}</th>
                <th scope="col">{getTranslate('validateVoteTitle')}</th>
                <th scope="col">{getTranslate('validateFork')}</th>
            </tr>
            </thead>
            <tbody>
            {arr.length > 0 ? arr.map(r =>
                <tr key={r.id}>
                    <td>{r.id}</td>
                    <td>{r.sheet}</td>
                    <td>{r.vote}</td>
                    <td>{r.fork}</td>
                </tr>
            )
                :
                alternativeArr.map(r =>
                    <tr key={r.id}>
                        <td>{r.id}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )
            }
            </tbody>
        </table>

    )
}

export default ValidateTable;