import React, { useState } from "react";
import no_avatar from "../../../assets/img/avatar.png";
import axios from "../../../api";
import ProfileModal from "./Modals/Profile";
import SeekPositionModal from "./Modals/SeekPosition";
import ProposalModal from "./Modals/Proposal";
import BenefitsModal from "./Modals/Benefits";
import ConfirmModal from "./Modals/ConfirmModal";

const OwnerCabinet = (props) => {
    const {candidate, getTranslate, language, webV, TITLE, LAST_NAME, FIRST_NAME, PROPOSALS_TITLE, PROMO_MESSAGE} = props;
    const [avatar, setAvatar] = useState(candidate.image ? candidate.image : no_avatar);
    const [profile, setProfile] = useState({...candidate});
    const [canLaunchCampaign, setCanLaunchCampaign] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isProposals, setIsProposals] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [errorImg, setErrorImg] = useState(null);
    const [error, setError] = useState(false);
    const checkProfile = () => {
        return profile[FIRST_NAME] !== '' && profile[LAST_NAME] !== '' && profile.seek_position[TITLE] !== '' &&
        profile.seek_position.public_pages !== '' && profile.image !== '/uploads/user_photos/default_user.jpg' && profile.email !== '' &&
        profile.cv_links[0] !== '' && profile.campaign[PROPOSALS_TITLE].brief[0] !== '' &&
        profile.campaign[PROPOSALS_TITLE].details[0] !== '' &&
        profile.campaign[PROPOSALS_TITLE].timeline[0] !== '' && profile.campaign[PROMO_MESSAGE] !== '';
    }

    const photoUploadHandler = async (e) => {
        const value = e.target.files[0];
        const imageData = new FormData();
        imageData.append('image', value);
        value?.size / 1024 / 1024 < 5 ?
            await axios.put(`ratees/${profile.ratee}/set/image`, imageData).then(r => {
                setProfile((prevState) => {
                    let newProfile = JSON.parse(JSON.stringify(prevState));
                    newProfile.image = r.data.data.image;
                    return newProfile;
                });
                setAvatar(r.data.data.image);
                setErrorImg(null);
            })
            :
            setErrorImg(getTranslate('errorImage'))
    }
    const campaignCreateHandler = async (e) => {
        const value = e.target.value;
        const formData = new FormData();
        formData.append('end_date', value);
        formData.append('ratee', candidate.ratee);
        await axios.put(`campaigns/${candidate.campaign.campaign_id}`, formData);
    };
    const verifyProfileAxios = async () => {
        await axios.post(`campaigns/${candidate.campaign.campaign_id}/verifycheck`)
    }
    const onClickHandler = async () => {
        setCanLaunchCampaign(checkProfile());
        setError(!checkProfile());
        setShowConfirmModal(true);
    }

    let someDate = new Date();
    let anotherDate = new Date();
    let numberOfDaysToAdd = 30;
    let numberOfDaysToAddMin = 4;
    let result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    let min_result = anotherDate.setDate(someDate.getDate() + numberOfDaysToAddMin);

    return (
        <>
            {webV ?
                <>
                    <div className="col-md-4 owner-cabinet">
                        <div>
                            <SeekPositionModal
                                getTranslate={getTranslate}
                                profile={profile}
                                setProfile={setProfile}
                                disabled={false}
                                language={language}
                                TITLE={TITLE}
                            />
                            <div className="mt-3">
                                <b>{getTranslate('newBoss').toLowerCase()}</b>
                            </div>
                        </div>
                        <div className="mt-3" style={{height: '180px'}}>
                            <img width={150} height={130} style={{objectFit: "contain"}} src={avatar} alt={avatar}/>
                            <input type="file" className="avatar-input" onChange={photoUploadHandler}/>
                            <p style={{color: 'red', opacity: '0.6'}}>{errorImg}</p>
                        </div>
                        <div>
                            <ProfileModal
                                webV={webV}
                                getTranslate={getTranslate}
                                language={language}
                                profile={profile}
                                setProfile={setProfile}
                                disabled={false}
                                LAST_NAME={LAST_NAME}
                                FIRST_NAME={FIRST_NAME}
                            />
                            <p className="mt-4 mb-0">{getTranslate('campaignTo')}</p>
                            <input type="date" onChange={campaignCreateHandler} disabled={profile?.campaign?.verified}
                                   defaultValue={candidate?.campaign?.end_date}
                                   min={new Date(min_result).toISOString().split("T")[0]}
                                   max={new Date(result).toISOString().split("T")[0]}
                            />

                            <div className="my-5">
                                    <button onClick={onClickHandler} className={"btn btn-success"}>
                                        {getTranslate('launchCampaign')}
                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="column col-md-6 row owner-cabinet">
                        <div>
                            <br/><br/><br/>
                            <b>{getTranslate('whyMe')}</b>
                            <div className="mt-3">
                                <ul>
                                    {[0, 1, 2].map(modal =>
                                        <ProposalModal profile={profile} setProfile={setProfile}
                                                       getTranslate={getTranslate}
                                                       disabled={false} i={modal} key={modal}
                                                       PROMO_MESSAGE={PROMO_MESSAGE}
                                                       language={language} PROPOSALS_TITLE={PROPOSALS_TITLE}/>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div>
                            <b>{getTranslate('whyCan')}</b>
                            <div className="mt-3">
                                <ul>
                                    {['cv_links', 'pr_links', 'endorsements'].map(modal =>
                                        <BenefitsModal
                                            key={modal}
                                            getTranslate={getTranslate}
                                            language={language}
                                            profile={profile}
                                            setProfile={setProfile}
                                            variant={modal}
                                        />
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showConfirmModal &&
                        <ConfirmModal content={getTranslate('campaignLaunchConfirmText')} verifyProfileAxios={verifyProfileAxios}
                                      canLaunchCampaign={canLaunchCampaign} error={error}
                                      getTranslate={getTranslate} close={setShowConfirmModal} />}
                </>
                :
                <>
                    <div className="guest-mob">
                        <div className="guest-mob-title">
                            <div style={{width: 'fit-content', maxWidth: '100%', margin: '0 auto'}}>
                                <SeekPositionModal
                                    getTranslate={getTranslate}
                                    profile={profile}
                                    setProfile={setProfile}
                                    disabled={false}
                                    language={language}
                                    TITLE={TITLE}
                                />
                            </div>
                            <div><b>{getTranslate('newBoss')}</b></div>
                        </div>
                        <div className="guest-mob-bio">
                            <div className="guest-mob-bio-block">
                                <div className="guest-mob-bio-image">
                                    <label htmlFor="file-input">
                                        <img src={avatar} alt={avatar}/>
                                    </label>
                                    <input type="file" id="file-input" className="avatar-input"
                                           onChange={photoUploadHandler}/>
                                </div>
                                <div><p style={{color: 'red', opacity: '0.6'}}>{errorImg}</p></div>
                            </div>
                            <div className="guest-mob-bio-block">
                                <div>
                                    <ProfileModal
                                        webV={webV}
                                        getTranslate={getTranslate}
                                        language={language}
                                        profile={profile}
                                        setProfile={setProfile}
                                        disabled={false}
                                        LAST_NAME={LAST_NAME}
                                        FIRST_NAME={FIRST_NAME}
                                    />
                                </div>
                                <div className="guest-mob-bio-block-campaign">
                                    <p>{getTranslate('campaignTo')}</p>
                                    <input type="date" onChange={campaignCreateHandler}
                                           disabled={profile?.campaign?.verified}
                                           defaultValue={candidate.campaign?.end_date}
                                           min={new Date(min_result).toISOString().split("T")[0]}
                                           max={new Date(result).toISOString().split("T")[0]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="guest-mob-proposals" style={{marginTop: '40px'}}>
                            <div className="guest-mob-proposals-buttons">
                                <div>
                                    <button className={isActive === 1 ? 'active' : ''} onClick={() => {
                                        setIsProposals(false);
                                        setIsActive(1);
                                    }}>{getTranslate('whyMe')}
                                    </button>
                                    <button className={isActive === 2 ? 'active' : ''} onClick={() => {
                                        setIsProposals(true);
                                        setIsActive(2);
                                    }}>{getTranslate('whyCan')}
                                    </button>
                                </div>
                            </div>
                            <div className="guest-mob-proposals-content">
                                {!isProposals ?
                                    <ul>
                                        {[0, 1, 2].map(modal =>
                                            <li key={modal}>
                                                <ProposalModal profile={profile} setProfile={setProfile}
                                                               getTranslate={getTranslate}
                                                               disabled={false} i={modal} PROMO_MESSAGE={PROMO_MESSAGE}
                                                               language={language} PROPOSALS_TITLE={PROPOSALS_TITLE}/>
                                            </li>
                                        )}
                                    </ul>
                                    :
                                    <ul>
                                        {['cv_links', 'pr_links', 'endorsements'].map(modal =>
                                            <li key={modal}>
                                                <BenefitsModal getTranslate={getTranslate} profile={profile}
                                                               setProfile={setProfile} variant={modal}
                                                               language={language}
                                                />
                                            </li>
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className="guest-mob-button">
                            <button onClick={onClickHandler} className={"btn btn-success my-2"}>
                                {getTranslate('launchCampaign')}
                            </button>
                        </div>
                    </div>
                    {showConfirmModal &&
                        <ConfirmModal content={getTranslate('campaignLaunchConfirmText')} verifyProfileAxios={verifyProfileAxios}
                                      canLaunchCampaign={canLaunchCampaign} error={error}
                                      getTranslate={getTranslate} close={setShowConfirmModal}/>}
                </>
            }

        </>
    )
}

export default OwnerCabinet