import React, {useEffect, useState} from "react";
import axios from "../../api";
import Loader from "../../components/Loader";
import Ranking from "../../components/Ranking";

const Rankings = (props) => {
    const {getTranslate, isAdmin} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const webV = window.innerWidth > 1024

    useEffect(() => {
        setIsLoading(true);
        const Promise = axios.get('campaigns/closed',);
        Promise.then(response => {
            setCampaigns(response?.data?.data?.campaigns);
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }).catch(e => {
            console.error(e);
            setIsLoading(false);
        })
    }, [isAdmin])

    return (
        <div style={{marginTop: '100px'}}>
            <div className="container ps-4">
                {webV ?
                    <h3>{getTranslate('campaignsRanking')}</h3>
                    :
                    <div className="pb-4">
                        <b className="my-auto">{getTranslate('campaignsRanking')}</b>
                    </div>
                }
            </div>
            <div className="container mt-5">
                {isLoading ? <Loader/>
                    :
                    campaigns && campaigns.length > 0 ?
                        campaigns.map((campaign, index) => <Ranking key={campaign.campaign.campaign_id}
                                                                    getTranslate={getTranslate} index={index + 1}
                                                                    campaign={campaign} webV={webV}/>)
                        :
                        <h4 className="ms-3">{getTranslate('noActiveCampaigns')}</h4>
                }
            </div>
        </div>
    )
}

export default Rankings;