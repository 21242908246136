import React, {useState} from "react";
import avatar from "../../../assets/img/avatar.png";
import qwe from "../../../assets/img/ewq.jpg";
import ewq from "../../../assets/img/qweqwe.jpg";
import flag from "../../../assets/img/flag.jpeg";
import ProposalModal from "./Modals/ProposalModal";
import BenefitsModal from "./Modals/BenefitsModal";
import moment from "moment";
import AdminVerifyModal from "./Modals/AdminVerifyModal";
import {TELEGRAM_BOT_RATER} from "../../../bot";
import useAnalyticsEventTracker from "../../../googleAnalytics/useAnalyticsEventTracker";

const GuestCabinet = (props) => {
    const {candidate, getTranslate, language, webV, TITLE, LAST_NAME, FIRST_NAME, PROPOSALS_TITLE, PROMO_MESSAGE} = props;
    const [campaignVerified, setCampaignVerified] = useState(candidate.campaign.verified);

    const TELEGRAM_URL_CAMPAIGN = TELEGRAM_BOT_RATER + '?start=' + candidate.campaign?.campaign_id;
    const [isProposals, setIsProposals] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const voteGA = useAnalyticsEventTracker('MainPage', 'click_vote', 'header');

    return (
        <>
            {webV ?
                <>
                    <div className="guest-web col-md-4">
                        <b className="guest-web-b"><a href={candidate.seek_position.public_pages}>{candidate?.seek_position[TITLE]}</a></b>
                        <b className="guest-web-b guest-web-b-two" >{getTranslate('newBoss')}</b>
                        <div className="guest-web-block">
                            <img height={200} src={candidate.image !== '' ? candidate.image : avatar} alt="Avatar"/>
                        </div>
                        <p className="my-2"><b>{candidate[LAST_NAME] + ' ' + candidate[FIRST_NAME]}</b></p>
                        <p className="guest-web-p" >{getTranslate('campaignTo')}</p>
                        <span>{moment(candidate?.campaign?.end_date).format('DD.MM.YYYY')}</span>
                        <div className="guest-web-block-two">
                            {props.isAdmin ?
                                <button disabled={campaignVerified} onClick={() => setShowVerifyModal(true)} className="btn btn-custom btn-lg mt-3"
                                   >{getTranslate('verifyButton')}
                                </button>
                                :
                                <a onClick={() => voteGA()} href={TELEGRAM_URL_CAMPAIGN} className="btn btn-custom btn-lg mt-3"
                                   >{getTranslate('vote')}
                                </a>
                            }
                        </div>
                    </div>
                    <div className="guest-web-two column col-md-8 row">
                        <div className="guest-web-two-block">
                            <b className="guest-web-two-block-b">b</b>
                            <b>{getTranslate('whyMe')}</b>
                            <div className="mt-3" style={{height: '200px'}}>
                                <ul>
                                    {candidate.campaign[PROPOSALS_TITLE]?.brief.length > 0 && candidate.campaign[PROPOSALS_TITLE]?.brief.map((proposal, index) => (
                                        <ProposalModal key={index} index={index} mobV={false}
                                                       getTranslate={getTranslate}
                                                       content={candidate.campaign[PROPOSALS_TITLE]}/>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div style={{marginTop: '1rem'}}>
                            <b>{getTranslate('whyCan')}</b>
                            <div className="mt-1" style={{paddingTop: '5px'}}>
                                <ul>
                                    {['cv_links', 'pr_links', 'endorsements'].map(modal =>
                                        <BenefitsModal key={modal} mobV={false} getTranslate={getTranslate}
                                                       content={candidate[modal]} variant={modal}/>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showVerifyModal &&
                        <AdminVerifyModal setCampaignVerified={setCampaignVerified} campaign_id={candidate.campaign.campaign_id} close={setShowVerifyModal} getTranslate={getTranslate} />
                    }
                </>
                :
                <div className="guest-mob">
                    <div className="guest-mob-title">
                        <b><a href={candidate.seek_position.public_pages}>{candidate?.seek_position[TITLE]}</a></b>
                        <div><b>{getTranslate('newBoss').toLowerCase()}</b></div>
                    </div>
                    <div className="guest-mob-bio">
                        <div className="guest-mob-bio-image"><img
                            src={candidate.image !== '' ? candidate.image : avatar}
                            alt="avatar"/></div>
                        <div className="guest-mob-bio-block">
                            <div>
                                <h3>{candidate[LAST_NAME]}</h3>
                                <h3>{candidate[FIRST_NAME]}</h3>
                            </div>
                            <div>
                                <p>{getTranslate('campaignTo')}</p>
                                <p>{moment(candidate?.campaign?.end_date).format('DD.MM.YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="guest-mob-proposals">
                        <div className="guest-mob-proposals-buttons">
                            <div>
                                <button className={isActive === 1 ? 'active' : ''} onClick={() => {
                                    setIsProposals(false);
                                    setIsActive(1);
                                }}>
                                    {getTranslate('whyMe')}
                                </button>
                                <button className={isActive === 2 ? 'active' : ''} onClick={() => {
                                    setIsProposals(true);
                                    setIsActive(2);
                                }}>
                                    {getTranslate('whyCan')}
                                </button>
                            </div>
                        </div>
                        <div className="guest-mob-proposals-content">
                            {!isProposals ?
                                <ul>
                                    {candidate.campaign[PROPOSALS_TITLE]?.brief.length > 0 && candidate.campaign[PROPOSALS_TITLE]?.brief.map((proposal, index) => (
                                        <li key={index}>
                                            <ProposalModal mobV={true} getTranslate={getTranslate} index={index}
                                                           content={candidate.campaign[PROPOSALS_TITLE]}/>
                                        </li>
                                    ))}
                                </ul>
                                :
                                <ul>
                                    {['cv_links', 'pr_links', 'endorsements'].map(modal =>
                                        <li key={modal}>
                                            <BenefitsModal mobV={true} getTranslate={getTranslate}
                                                           content={candidate[modal]} variant={modal}/>
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                    </div>
                    <div className="guest-mob-button">
                        {props.isAdmin ?
                            <button onClick={() => setShowVerifyModal(true)} disabled={campaignVerified} className="btn btn-custom btn-lg my-4">{getTranslate('verifyButton')}</button>
                            :
                            <button onClick={() => voteGA()} className="btn btn-custom btn-lg my-4"><a style={{color: "inherit"}} href={TELEGRAM_URL_CAMPAIGN}>{getTranslate('vote')}</a></button>
                        }
                    </div>
                    {showVerifyModal &&
                        <AdminVerifyModal setCampaignVerified={setCampaignVerified} campaign_id={candidate.campaign.campaign_id} close={setShowVerifyModal} getTranslate={getTranslate} />
                    }
                </div>
            }

        </>
    )
}

export default GuestCabinet;