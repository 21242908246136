import React, {useState} from "react";
import {Col, Dropdown} from "react-bootstrap";
import burger from '../../assets/img/icon.png'
import Authentication from "../Authentication";
import {fetchLogOut} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import AdminComment from "../AdminComment";
import useAnalyticsEventTracker from "../../googleAnalytics/useAnalyticsEventTracker";
import {useCookies} from "react-cookie";
import ConfirmModal from "../Cabinet/OwnerCabinet/Modals/ConfirmModal";
import {TELEGRAM_BOT_RATEE} from "../../bot";
import info from '../../assets/img/info.svg';

export const Navigation = (props) => {
  const {getTranslate, language, setLanguage, isAdmin} = props;
  const dispatch = useDispatch();
  const [showPopUpAuth, setShowPopUpAuth] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPopUpComment, setShowPopUpComment] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(true);
  const id = localStorage.getItem('ratee');
  let profile = useSelector(state => state.main.user);
  const comment = profile.comment;
  const profileId = profile.ratee;
  const [cookies, setCookie] = useCookies(['roles']);
  const TELEGRAM_URL_CANDIDATE = TELEGRAM_BOT_RATEE;
  const logOutHandler = () => {
    dispatch(fetchLogOut());
    window.location.replace('/')
    setCookie("roles", []);
  }
  const headerLanguage = ['RU', 'EN', 'QZ'].includes(language) ? language : 'RU';
  const contactUsGA = useAnalyticsEventTracker('Contact us', 'click_serv', 'header');
  const campaignsGA = useAnalyticsEventTracker('Campaigns', 'click_comp', 'header');
  const becomeCandidateGA = useAnalyticsEventTracker('BecomeCandidate', 'click_become', 'header');

  return (
      <div id="navigation">
        <header
            className="flex-wrap py-1 fixed-top">
          <div className="container d-flex align-items-center">
          <Col>
            <Dropdown >
              <Dropdown.Toggle id="dropdown-custom-components">
                <div>
                <span style={{ display: 'block', fontSize: '10px', letterSpacing: '2.4px', textAlign: 'left'}}>
                  JAÑA QAZAQSTAN</span>
                  <span style={{ display: 'block', fontSize: '18px', letterSpacing: '3px', fontWeight: 600,
                    textAlign: 'left'}}>QYZMETKE</span>
                  <span style={{ display: 'block', fontSize: '10px', textAlign: 'start'}}>{getTranslate('reserve')}</span>
                </div>
                <div className="logo">
                  <img className="info" src={info} alt="info-logo"/>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item tag='Link' href={`/`}>{getTranslate('home')}</Dropdown.Item>
                <Dropdown.Item onClick={() => contactUsGA()} href={'/about'} >{getTranslate('service')}</Dropdown.Item>
                <Dropdown.Item href={'/contacts'} >{getTranslate('contacts')}</Dropdown.Item>
                <Dropdown.Item href={'/service-terms'}>{getTranslate('useTerms')}</Dropdown.Item>
                <Dropdown.Item href={'/faq'} >{getTranslate('FAQ')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          {window.innerWidth > 1024 && (
            <div className="nav col-md-4 col-md-auto justify-content-between mb-md-0">
              <a onClick={() => campaignsGA()} href="/campaigns" className="nav-link px-2 link-light">{getTranslate('campaigns')}</a>
              <a href="/rankings" className="nav-link px-2 link-light">{getTranslate('rankings')}</a>
            </div>
          )}

          <Col className="text-end" style={{display: 'flex',justifyContent: 'end'}}>
            <Dropdown className="text-center">
              <Dropdown.Toggle style={{background: 'none', lineHeight: '28px'}} id="dropdown-custom-components">
                <span>{headerLanguage}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setLanguage('RU')}>RU</Dropdown.Item>
                <Dropdown.Item onClick={() => setLanguage('EN')}>EN</Dropdown.Item>
                <Dropdown.Item onClick={() => setLanguage('QZ')}>QZ</Dropdown.Item>
              </Dropdown.Menu>
                {showLanguageModal && window.location.pathname.includes('profiles') &&
                    <div className="language-modal" style={window.innerWidth < 1024 ? {marginTop: '14px'} : {marginTop: '8px'}} onClick={() => setShowLanguageModal(false)}>
                      <span style={{color: 'black'}}>{getTranslate(Number(id) === profileId ? 'languageModalOwner' : 'languageModalGuest')}</span>
                    </div>
                }
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-custom-components">
                <div>
                  <img className="hamburger" width={20} src={burger} alt="burger"/>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {id && id !== '' ?
                   (<>
                     <Dropdown.Item href={`/profiles/${id}`} >{getTranslate('editProfile')}</Dropdown.Item>
                     {isAdmin && profileId && <Dropdown.Item onClick={() => setShowPopUpComment(!showPopUpComment)} >
                         {getTranslate('addComment')}</Dropdown.Item>}
                     <Dropdown.Item href={'/validate'} >{getTranslate('validateVote')}</Dropdown.Item>
                     <Dropdown.Item href={TELEGRAM_URL_CANDIDATE} >{getTranslate('deleteProfile')}</Dropdown.Item>
                     {window.innerWidth < 1024 && (
                         <>
                           <Dropdown.Item onClick={() => campaignsGA()} href={"/campaigns"} >{getTranslate('campaigns')}</Dropdown.Item>
                           <Dropdown.Item href={"/rankings"} >{getTranslate('rankings')}</Dropdown.Item>
                         </>
                     )}
                     <Dropdown.Item onClick={(logOutHandler)} >{getTranslate('logOut')}</Dropdown.Item>
                   </>)
                    :
                  (
                      <>
                    <Dropdown.Item onClick={() => setShowPopUpAuth(!showPopUpAuth)} >{getTranslate('signIn')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      setShowConfirmModal(!showConfirmModal);
                      becomeCandidateGA();
                    }} >{getTranslate('becomeCandidate')}</Dropdown.Item>
                    <Dropdown.Item href={'/validate'} >{getTranslate('validateVote')}</Dropdown.Item>
                    {window.innerWidth < 1024 && (
                        <div className="nav col-md-3 col-md-auto mb-2 justify-content-between mb-md-0">
                          <Dropdown.Item href={"/campaigns"} >{getTranslate('campaigns')}</Dropdown.Item>
                          <Dropdown.Item href={"/rankings"} >{getTranslate('rankings')}</Dropdown.Item>
                        </div>
                    )}
                  </>
                  )
                }

              </Dropdown.Menu>
            </Dropdown>
          </Col>
          </div>
        </header>
        {showPopUpAuth && <Authentication getTranslate={getTranslate} close={setShowPopUpAuth} />}
        {showPopUpComment && <AdminComment comment={comment} id={profileId} getTranslate={getTranslate} close={setShowPopUpComment} />}
        {showConfirmModal && <ConfirmModal becomeCandidate={true} href={TELEGRAM_URL_CANDIDATE} content={getTranslate('campaignCreateConfirmText')} getTranslate={getTranslate} close={setShowConfirmModal} />}
      </div>
  )
}
