import React, {useEffect} from "react";
import {
    PopUpCloseBtn,
    PopUpContainer,
    PopUpContent,
    PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage
} from "../../../../Authentication/AuthForm/styles";
import {Button} from "react-bootstrap";
import {TELEGRAM_BOT_RATEE} from "../../../../../bot";

const ConfirmModal = (props) => {
    const {getTranslate, close, content, href, error, canLaunchCampaign, becomeCandidate, verifyProfileAxios} = props;
    let errorMsg = getTranslate('errorCampaign');
    useEffect(() => {
        document.getElementById('msg-div').innerHTML = error ? errorMsg : content;
    }, [error])
    return (
        <PopUpContainer onClick={() => close(false)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <PopUpCloseBtn onClick={() => close(false)}/>
                </PopUpHeader>
                <PopUpContent>
                    <p id="msg-div"></p>
                </PopUpContent>
                <PopUpFooterWrapper>
                    {becomeCandidate ?
                        <Button className="ms-auto" variant="success">
                            <a style={{textDecoration: 'none', color: 'inherit'}}
                               href={href}>{getTranslate('confirmButton')}</a>
                        </Button>
                        :
                        !canLaunchCampaign ?
                            <Button className="ms-auto" variant="success"
                                    style={{textDecoration: 'none', color: 'white'}}
                                    onClick={() => close(false)}
                            >{getTranslate('ok')}
                            </Button>
                            :
                            <Button className="ms-auto" variant="success"
                                    style={{textDecoration: 'none', color: 'white'}}
                                    onClick={async () => {
                                        await verifyProfileAxios();
                                        window.location.replace(TELEGRAM_BOT_RATEE);
                                    }}>{getTranslate('confirmButton')}
                            </Button>
                    }
                </PopUpFooterWrapper>
            </PopUpStage>
        </PopUpContainer>
    )
}

export default ConfirmModal;