import React, {useState} from "react";
import {
    PopUpCloseBtn,
    PopUpContainer,
    PopUpContent, PopUpFooterWrapper,
    PopUpHeader, PopUpRemoveBtn,
    PopUpStage
} from "../../../Authentication/AuthForm/styles";
import {Form} from "react-bootstrap";
import linkedin from '../../../../assets/img/linkedin.svg';
import youtube from '../../../../assets/img/youtube.svg';
import plus from '../../../../assets/img/plus.svg';

const BenefitsModal = (props) => {
    const {variant, content, mobV, getTranslate} = props;
    const [isVisible, setIsVisible] = useState(false);
    const title = variant === 'cv_links' ? getTranslate('placeholderExperience') : variant === "pr_links" ?
        getTranslate('placeholderPR') : variant === "endorsements" ? getTranslate('placeholderEndor') : null;
    const image = variant === 'cv_links' ? linkedin : variant === "pr_links" ? youtube :
        variant === "endorsements" ? plus : null;

    return(
        <>
            <div className="proposal-guest">
                <div style={{paddingBottom: 0}}>
                    <img style={{width: '28px', height: '28px'}} src={image} alt="logo"/>
                    <p onClick={() => setIsVisible(!isVisible)}>{title}</p>
                </div>
            </div>
            {isVisible && <PopUpContainer onClick={() => setIsVisible(!isVisible)}>
                <PopUpStage onClick={(e) => e.stopPropagation()}>
                    <PopUpHeader>
                        <PopUpCloseBtn onClick={() => setIsVisible(!isVisible)}/>
                    </PopUpHeader>
                    <PopUpContent>
                        {content.map((c, index) =>
                            <div key={c}><Form.Text><a href={c}><b>{index + 1}. {c}</b></a></Form.Text></div>
                        )}
                    </PopUpContent>
                    <PopUpFooterWrapper>
                    </PopUpFooterWrapper>
                </PopUpStage>
            </PopUpContainer>}
        </>
    )
}

export default BenefitsModal;