import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {
    PopUpCloseBtn,
    PopUpContainer,
    PopUpContent, PopUpFooterWrapper,
    PopUpHeader,
    PopUpStage,
    PopUpTitle
} from "./styles";

const AuthForm = (props) => {

    return (
        <PopUpContainer onClick={() => props.close(false)}>
            <PopUpStage onClick={(e) => e.stopPropagation()}>
                <PopUpHeader>
                    <PopUpTitle>{props.getTranslate('auth')}</PopUpTitle>
                    <PopUpCloseBtn onClick={() => props.close(false)}></PopUpCloseBtn>
                </PopUpHeader>
                <PopUpContent>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{props.getTranslate('phoneNumber')}</Form.Label>
                            <Form.Control className="input" onChange={props.change} name="phone" type="test" placeholder="+7xxxxxxx" />
                            {props.error ?
                                <Form.Text style={{opacity: 0.6, color: 'red'}}>
                                    {props.message}
                                </Form.Text>
                                :
                                <></>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>{props.getTranslate('password')}</Form.Label>
                            <Form.Control className="input" onChange={props.change} name="password" type="password" placeholder={props.getTranslate('password')} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label={props.getTranslate('saveMe')} />
                        </Form.Group>

                    </Form>
                </PopUpContent>
                <PopUpFooterWrapper>
                    <Button onClick={props.submit} variant="primary" type="submit">
                        {props.getTranslate('signIn')}
                    </Button>
                </PopUpFooterWrapper>

            </PopUpStage>
        </PopUpContainer>
    )
}

export default AuthForm;