import React from "react";

const NoProfile = (props) => {
    const {getTranslate} = props;

    return(
        <div className="page-not-found">
            <h1>404</h1>
            <h4>{getTranslate('inactiveProfile')}</h4>
            <a href="/campaigns">{getTranslate('takeBackToActiveCampaigns')}</a>
        </div>
    )
}

export default NoProfile;