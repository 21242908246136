import React, {useEffect, useState} from "react";
import axios from "../../../../../api";
import SeekModal from "./Modal";

const SeekPosition = (props) => {
    const {disabled, profile, setProfile, getTranslate, language, TITLE} = props;
    const campaignId = profile?.campaign?.campaign_id;
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const initialForm = {['seek_position']: profile.seek_position}
    const [title, setTitle] = useState(profile?.seek_position[TITLE] !== '' &&
        profile?.seek_position[TITLE] !== undefined ? profile?.seek_position[TITLE] : getTranslate('placeholderAgency'));
    const [form, setForm] = useState(initialForm);
    const saveCampaignChangesHandler = async () => {
        setIsLoading(true);
        await axios.put(`campaigns/${campaignId}`, form).then((r) => {
            setIsLoading(false);
            if (r.status === 200 && r.data.code === 0) {
                setIsVisible(false);
            } else if (r.status === 200 && r.data.message === "Link is not valid!") {
                setError(getTranslate('errorLink'))
            } else if (r.status === 200 && r.data.message === "This field may not be blank.") {
                setError(getTranslate('errorName'))
            } else {
                setError(getTranslate('errorRequest'))
            }
        }).catch(e => {
            setIsLoading(false);
            setError(getTranslate('errorDate'));
        });
        setTitle(profile.seek_position[TITLE]);
    }
    const onChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if ([TITLE, 'public_pages'].includes(name)) {
            setProfile(current => {
                return {
                    ...current,
                    seek_position: {
                        ...current.seek_position,
                        [name]: value,
                    },
                };
            });
            setForm(current => {
                return {
                    ...current,
                    seek_position: {
                        ...current.seek_position,
                        [name]: value,
                    },
                };
            });
        }
    };
    useEffect(() => {
        profile?.seek_position[TITLE] !== '' && profile?.seek_position[TITLE] !== undefined ? setTitle(profile?.seek_position[TITLE]) :
            setTitle(getTranslate('placeholderAgency'));
    }, [language])
    return (
        <>
            <div>
                <button disabled={disabled} onClick={() => setIsVisible(!isVisible)}
                        className="form-control text-start mt-3">{title}</button>
            </div>
            {isVisible && <SeekModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                getTranslate={getTranslate}
                onChangeHandler={onChangeHandler}
                profile={profile}
                TITLE={TITLE}
                saveCampaignChangesHandler={saveCampaignChangesHandler}
                isLoading={isLoading}
                language={language}
            />}
        </>
    )
}

export default SeekPosition;