import React from "react";
import CommentForm from "./CommentForm";

const AdminComment = (props) => {
    const {getTranslate, close, comment, id} = props;

    return(
        <CommentForm comment={comment} id={id} getTranslate={getTranslate} close={close}/>
    )
}

export default AdminComment;