export const translate = {
    title: ['Заголовок', 'Heading', 'Атау'],
    home: ['Главная', 'Home', 'Басты бет'],
    campaign: ['Кампания', 'Campaign', 'Hауқан'],
    campaigns: ['Кампании', 'Campaigns', 'Hауқандар'],
    rankings: ['Рэнкинг', 'Ranking', 'Рэнкинг'],
    service: ['О сервисе', 'About', 'Сервис туралы'],
    contacts: ['Контакты', 'Contacts', 'Байланысу'],
    useTerms: ['Условия использования', 'Terms of Service', 'Пайдалану ережелері'],
    FAQ: ['Часто задаваемые вопросы', 'FAQ', 'Жиі қойылатын сұрақтар'],
    ok: ['Ок', 'Ok', 'Ок'],
    reserve: ['народный кадровый резерв', 'civilian corps reserve', 'қабілетті жандандыру резерві'],
    editProfile: ['Редактировать профиль', 'Edit profile', 'Профильді өңдеу'],
    deleteProfile: ['Удалить профиль', 'Delete profile', 'Профильді жою'],
    logOut: ['Выйти', 'Exit', 'Шығу'],
    signIn: ['Авторизоваться', 'Sign in', 'Авторизациялау'],
    becomeCandidate: ['Стать кандидатом', 'Become a candidate', 'Кандидат болу'],
    validateVote: ['Валидировать голос', 'Validate vote', 'Дауысты растау'],
    boss: ['Глава службы', 'Head of Public Service', 'Қызмет басшысы'],
    candidate: ['Кандидат', 'Candidate', 'Кандидат'],
    tagline: ['Достоин? Поддержи', 'Fits on merit? Support', 'Ұстанымы сай ма? Қолда'],
    more: ['Подробнее', 'Details', 'Толығырақ'],
    unverified: ['Неподтвержденные', 'Unverified', 'Расталмаған'],
    noActiveCampaigns2: ['Активных кампаний нет', 'There are no active campaigns', 'Белсенді науқандар жоқ'],
    noActiveCampaigns: ['Завершенных кампаний нет', 'There are no completed campaigns', 'Аяқталған науқандар жоқ'],
    campaignsRanking: ['Рэнкинг кампаний по итогам голосования', 'Ranking of campaigns by voting results', 'Дауыс беру нәтиже бойынша науқандар рэнкингі'],
    404: ['К сожалению, данная страница не существует либо временно не активна', 'Unfortunately, this page does not exist or is temporarily inactive', 'Өкінішке орай, бұл бет жоқ немесе уақытша белсенді емес'],
    takeToHomePage: ['Вернуть на главную страницу', 'Back to the homepage', 'Басты бетке қайтару'],
    inactiveProfile: ['К сожалению, данный профайл не существует или не активен', 'Unfortunately, this profile does not exist or is not active', 'Өкінішке орай, бұл профиль жоқ немесе белсенді емес'],
    takeBackToActiveCampaigns: ['Вернуться к списку активных кампаний', 'Back to active campaigns list', 'Белсенді науқандар тізіміне оралу'],
    newBoss: ['Поддержи на нового главу', 'Support to head up', 'Жаңа басшыға қолда'],
    campaignTo: ['Кампания до', 'Campaign by', 'Науқан аяғы'],
    vote: ['Проголосовать', 'Cast vote', 'Дауыс беру'],
    whyMe: ['Почему меня?', 'Why me?', 'Неліктен мені?'],
    whyCan: ['Почему смогу?', 'Why capable?', 'Неге қабілеттім?'],
    launchCampaign: ['Запуск кампании', 'Launch Campaign', 'Науқанды бастау'],
    requestSent: ['Заявка отправлена', 'Applied for review', 'Растауға жіберілді'],
    save: ['Сохранить', 'Save', 'Сақтау'],
    delete: ['Удалить', 'Delete', 'Жою'],
    lastName: ['Фамилия', 'Last name', 'Terі'],
    firstName: ['Имя', 'First name', 'Аты'],
    email: ['Email', 'Email', 'Email'],
    telegram: ['Никнейм в Telegram', 'Telegram nickname', 'Telegram никнейм'],
    prefLang: ['Предпочитаемый язык', 'Preferred language', 'Тіл таңдауым'],
    errorLink: ['Ссылки должны начинаться с "https://..."', 'Links must begin with "https://..."', 'Сілтемелер "https://..." деп басталуы қажет'],
    errorName: ['Фамилия и имя обязательны к заполнению', 'First and last name are required', 'Аты мен тегі қажет'],
    errorRequest: ['Ошибка, повторите попытку позже', 'Error, try again later', 'Қате, әрекетті кейінірек қайталаңыз'],
    errorDate: ['Необходимо указать дату', 'Date is required', 'Күнді таңдау қажет'],
    errorImage: ['Максимальный размер файла 5 Мб', 'Maximum file size is 5 Mb', 'Максималды файл өлшемі 5 Мб'],
    errorCampaign: ['Необходимо заполнить обязательные поля: название и ссылка на сайт госоргана, фото, фамилия и имя, email, хотя бы одно предложение полностью (краткое описание, срок, детальное описание, промо-сообщение для Telegram), хотя бы одна ссылка на резюме. Остальное не обязательно.', 'Please fill in the required fields: title and link to the public authority website, photo, first and last name, email, at least one proposal in full (brief description, deadline, detailed description, promo message for Telegram), at least one link to CV. The rest is optional.', 'Міндетті өрістерді толтыру керек: мемлекеттік органның атауы және веб-сайтына сілтемесі, фото, тегі мен аты, электрондық поштасы, кем дегенде бір толық ұсыныс (қысқаша сипаттама, мерзім, толық сипаттама, Telegram үшін промо хабарлама), түйіндемеге кем дегенде бір сілтеме. Қалғаны міндетті емес.'],
    ActiveCampaignEdit: ['Активная кампания не может быть отредактирована', 'Active campaign cannot be edited', 'Белсенді науқанды өңдеу мүмкін емес'],
    placeholderAgency: ['Тут укажи гос.орган', 'Specify public authority', 'Мұнда мемлекеттік органды көрсетіңіз'],
    placeholderFullName: ['Тут укажи ФИО...', 'Fill in here your full name...', 'Мұнда толық аты-жөніңізді жазыңыз...'],
    inputAgencyTitle: ['кратко, н-р "МинФин", "МВД" и тп', 'briefly, e.g, "MinFin"', 'қысқаша, мыc., "МинФин", "Ішкі істер мин-гі")'],
    inputAgencyLink: ['', '', ''],
    inputAgencyBoss: ['Имя действующего главы', 'First name of the head', 'Басшының аты'],
    inputAgencyBossLastName: ['Фамилия действующего главы', 'Last name of the head', 'Басшының тегі'],
    inputAgencyBossPhoto: ['Ссылка на фото действующего главы', 'Link to photo of the head', 'Басшының суретіне сілтеме'],
    inputAgencyBossPublicLink: ['Ссылка на паблик действующего главы', 'Link to public profile of the head', 'Басшының паблик профиліне сілтеме'],
    inputProposalFirst: ['Тут укажи что сделаешь во-первых', 'Here, indicate your very first priority to get done', 'Мұнда орындайтын ең басты басымдықты көрсетіңіз'],
    inputProposalSecond: ['Во-вторых... (если нужно)', 'Second... (if needed)', 'Екінші... (қажет болса)'],
    inputProposalThird: ['И в-третьих... (тоже опционально)', 'Finally... (also optional)', 'Ақырында... (бұл да міндетті емес)'],
    inputBrief: ['264 знаков максимум', '264 chars max', '264 таңба максимум'],
    inputTimeLines: ['н-р, 6 мес. (64 знаков максимум)', 'e.g., 6 months (64 chars max)', 'мыс., 6 ай (64 таңба максимум)'],
    inputDetails: ['1024 знаков максимум', '1024 chars max', '1024 таңба максимум'],
    inputPromoMessage: ['264 знаков максимум', '264 chars max', '264 таңба максимум'],
    placeholderResume: ['linkedIn, или др', 'linkedIn, etc.', 'linkedIn, т.б.'],
    placeholderMedia: ['youtube и др', 'youtube, etc.', 'youtube, т.б.'],
    placeholderEndorsements: ['н-р, файл в облаке', 'e.g, a file in cloud', 'мыс., бұлттағы файл'],
    placeholderExperience: ['Тут мой опыт', 'My experience', 'Мұнда менің тәжірибем'],
    placeholderPR: ['Тут PR', 'My PR', 'Менің PR'],
    placeholderEndor: ['Тут мои поручители', 'My references', 'Кепілгерлерім'],
    errorFiveLink: ['Допустимо не более 5 ссылок', '5 links max allowed', '5 сілтеме максимум'],
    errorMinimalOneLink: ['Необходима минимум одна ссылка', 'At least one link is required', 'Кем дегенде бір сілтеме қажет'],
    addInput: ['Добавить поле', 'Add field', 'Өріс қосу'],
    durationTime: ['Срок реализации', 'Duration to implement', 'Іске асыру ұзақтығы'],
    openCampaigns: ['Открытые для голосования кампании', 'Campaigns open for voting', 'Дауыс беруге ашық науқандар'],
    deadline: ['до', 'by', 'аяғы'],
    outOf: ['из', 'out of', 'бары'],
    votes: ['голосов', 'votes', 'дауыстан'],
    forTheCandidate: ['за кандидата', 'for the candidate', 'кандидат үшін'],
    authError: ['Неверный номер или пароль', 'Wrong number or password', 'Нөмір немесе пароль қате'],
    auth: ['Авторизация', 'Sign in', 'Авторизациялау'],
    password: ['Пароль', 'Password', 'пароль'],
    saveMe: ['Запомнить меня', 'Remember me', 'Алда тануға сақтау'],
    phoneNumber: ['Номер телефона', 'Mobile number', 'Телефон нөмірі'],
    male: ['Господин', 'Mr.', 'Мырза'],
    female: ['Госпожа', 'Ms.', 'Ханым'],
    neutral: ['Предпочитаю не указывать', 'Prefer to not specify', 'Нақтыламауды жөн көремін'],
    agencyTitle: ['Гос.орган', 'Public authority', 'Мемлекеттік орган'],
    linkTitle: ['Ссылка на сайт гос.органа', 'Link to public authority website', 'Мемлекеттік органның веб-сайтына сілтеме'],
    briefTitle: ['Резюме предложения', 'Proposal summary', 'Ұсыныстың қысқашасы'],
    detailsTitle: ['Опиши детально что и как', 'Describe in detail what and how', 'Мұны қалай жасатпақшыcын'],
    promoMessageTitle: ['Краткий призыв по Telegram', 'Brief Telegram appeal', 'Telegram үшін қысқаша үндеу'],
    prLinkTitle: ['Ссылка на свои соцсети/паблики', 'Link to my social networks', 'Әлеуметтік желілеріме сілтеме'],
    experienceLinkTitle: ['Ссылка на моё резюме', 'Link to my CV', 'Түйіндемеге сілтеме'],
    endorLinkTitle: ['Ссылка на поручителя', 'Link to endorsement', 'Кепілгеріме сілтеме'],
    addComment: ['Добавить коммент', 'Add comment', 'Коммент қосу'],
    comment: ['Комментарий', 'Comment', 'Комментарий'],
    verifyButton: ['Верифицировать', 'Verify', 'Верификациялау'],
    for: ['ПОДДЕРЖКА', 'SUPPORT', 'ҚОЛДАУ'],
    against: ['ПРОТИВ', 'AGAINST', 'ҚАРСЫ'],
    confirmModal: ['Подтвердите верификацию профиля и разрешение на запуск кампании', 'Confirm profile verification and permission to launch the campaign', 'Профильді верификациялауды және науқанды бастауға рұқсатты растаңыз'],
    confirmButton: ['Подтвердить', 'Confirm', 'Растау'],
    validateNumber: ['Число валидации', 'Validation digit', 'Валидация нөмірі'],
    validateSlot: ['Ячейка', 'Cell', 'Ұяшық'],
    validateDepth: ['Глубина', 'Depth', 'Тереңдік'],
    validateFork: ['Форк', 'Fork', 'Форк'],
    validateVoteTitle: ['Голос', 'Vote', 'Дауыс'],
    validateSlotLabel: ['Введите координаты ячейки', 'Enter the cell coordinates', 'Ұяшық координаттарын енгізіңіз'],
    validateInfoFirst: ['Внимание! Вводить на латинице. Как работает механизм валидации узнай ', 'Warning. Enter data in the Latin alphabet. Find out how the validation mechanism works ', 'Назар аударыңыз! Латын әліппесінде енгізіңіз. Валидация механизмі қалай жұмыс істейтінін білу '],
    validateInfoSecond: ['смотреть в поле "Голос" в самой нижней заполненной строке. Данные в строках выше - определены путем обратного вычисления из валидируемого голоса', 'look in the "Vote" field in the lowest filled in line. The data on the lines above are determined by back-calculating from the validated vote', 'ең төменгі толтырылған жолдағы “Дауыс“ өрісін қараңыз. Жоғарыдағы жолдардағы деректер расталған дауыс беруден кері есептеу арқылы анықталады'],
    validateInfoThird: ['Легенда голоса по отношению к кандидату:', 'Legend of the vote in relation to the candidate:', 'Кандидатқа қатысты дауыс беру аңызы:'],
    here: ['тут', 'here', 'осында'],
    forValidateVote: ['Для валидации голоса', 'To validate the vote', 'Дауысты растау үшін'],
    campaignLaunchConfirmText: ['Внимание! Запуск кампании финально осуществляется через Telegram bot путем выбора соответствующей команды в меню и ввода пароля. Для финального запуска кампании необходимо пройти модерацию. Модерация означает, что ваш профиль станет публично доступен к просмотру на веб-сайте и вы сможете запустить кампанию только после проверки и верификации соответствия <a href="https://qyzmetke.com/service-terms">Условиям использования</a>. Ожидаемый срок модерации – до 3-х рабочих дней.\n\n' +
    'Если вы получили замечания при попытке запуска кампании в Telegram bot и внесли необходимые изменения в профиле на веб-сайте, вам необходимо повторно на веб-сайте нажать кнопку “Запуск кампании“ и затем кнопку “Подтвердить“, чтобы заново пройти верификацию.\n\n' +
    'После успешной верификации функционал по редактированию профиля будет заблокирован до окончания кампании для обеспечения публичного доступа к идентичной информации. При этом вы сможете изменить или удалить профиль после наступления срока окончания кампании.\n\n' +
    'Чтобы активировать проверку профиля, перейти в Telegram bot и продолжить запуск кампании нажмите “Подтвердить“.',
        'Attention! The campaign finally can be launched via the Telegram bot by selecting the appropriate command from the menu and entering a password. Moderation is required for the final launch of the campaign. Moderation means that your profile will become publicly available on the website and you will be able to launch the campaign only after checking and verifying its compliance with <a href=https://qyzmetke.com/service-terms>the Terms of Service</a>. Moderation is expected to take up to 3 business days.\n\n' +
        'If you received a comment when trying to launch a campaign in the Telegram bot and made the necessary changes in your profile on the website, you need to click "Launch Campaign" again on the website and then click "Confirm" to go through the verification again.\n\n' +
        'After successful verification, profile editing functionality will be blocked until the end of the campaign to ensure public access to identical information. However, you will be able to edit or delete your profile after the campaign ends.\n\n' +
        'To activate profile verification, switch to the Telegram bot and continue launching the campaign - click "Confirm"',
        'Назар аударыңыз! Науқанды бастау ең соңында Telegram бот арқылы менюдан сәйкес пәрменді таңдау және парольді енгізу арқылы жүзеге асырылады. Науқанның түпкілікті іске қосылуы үшін модерациядан өту керек. Модерация алдымен <a href=https://qyzmetke.com/service-terms>Пайдалану шарттарына</a> сәйкестігін тексеріп, верификациялағаннан кейін ғана сіздің профиліңіз веб-сайтта жалпыға қолжетімді болатынын және науқанды іске қоса алатыныңызды білдіреді. Күтілетін модерация мерзімі - 3 жұмыс күніне дейін.\n\n' +
        'Егер сіз Telegram ботында науқанды бастауға тырысқанда ескертулер мен түсініктемелер алып, одан әрі веб-сайттағы профиліңізге қажетті өзгерістерді енгізген болсаныз, верификациядан қайтадан өту үшін веб-сайттағы «Науқанды бастау» түймесін, содан кейін «Растау» түймесін қайта басып шығу қажет.\n\n' +
        'Сәтті өткен верификациядан кейін жалпыға бірдей ақпаратқа қолжетімділікті қамтамасыз ету үшін профильді өңдеу функциясы науқанның соңына дейін блокталады. Дегенмен, науқан аяқталғаннан кейін профиліңізді өңдеуге немесе жоюға болады.\n\n' +
        'Профиль тексеруді белсендіру мен Telegram ботына өтіп науқанды бастауды жалғастыру үшін “Растау“ түймесін басыңыз.'],
    campaignCreateConfirmText: ['Первичная регистрация кандидата осуществляется через Telegram бот. Нажмите кнопку “Подтвердить“, чтобы перейти в Telegram бот', 'The candidate\'s initial registration is done through the Telegram bot. Click "Confirm" to go to the Telegram bot', 'Кандидатты бастапқы тіркеу Telegram бот арқылы жүзеге асырылады. Telegram ботына өту үшін “Растау“ түймесін басыңыз'],
    ContactsPage: ['<b>Контакты</b>\n\n' +
    'Предложения и замечания, иная коммуникация принимается на:\n' +
    '<a href="qyzmetke.info@gmail.com">qyzmetke.info@gmail.com</a>\n\n' +
    'Обратная связь имеет высокий приоритет, но не гарантируется ввиду ограниченности ресурсов.',
        '<b>Contacts</b>\n\n' +
        'Suggestions and comments and other communications are accepted at:\n' +
        '<a href="qyzmetke.info@gmail.com">qyzmetke.info@gmail.com</a>\n\n' +
        'Feedback is a high priority, but is not guaranteed due to limited resources.',
        '<b>Байланысу</b>\n\n' +
        'Ұсыныстар мен ескертулер, басқа хабарламалар мұнда қабылданады:\n' +
        '<a href="qyzmetke.info@gmail.com">qyzmetke.info@gmail.com</a>\n\n' +
        'Кері байланыс жоғары басымдыққа ие, бірақ ресурс шектеулеріне байланысты кепілдік берілмейді.'],
    AboutPage: ['<b>О Сервисе</b>\n\n' +
    'Сервис или Сервис QYZMETKE – сервис, представленный на данном <a href="https://qyzmetke.com">вебсайте</a> и интегрированных компонентах.\n\n' +
    'Сервис QYZMETKE позволяет расширить кадровый резерв в госуправлении путем охвата инициатив на занятие гос постов для внедрения предложений по улучшению качества жизни и развитию страны с учетом замера их общественной поддержки, для чего имеет функционал:\n' +
    '- регистрация профиля кандидата;\n' +
    '- запуск кампании по поддержке кандидата;\n' +
    '- тайное голосование в поддержку/против кандидата;\n' +
    '- валидация голоса и результата голосования.\n\n' +
    'Сервис возник в ответ на потребность в поиске решений по повышению общественного доверия институтам государственного управления. Президент Казахстана Касым - Жомарт Токаев в свете трагических событий января 2022 года в Казахстане и последовавшей смены Правительства указал, что <a href="https://inbusiness.kz/ru/last/ministrom-mozhet-byt-kazhdyj-izmenenie-sistemy-ot-kasym-zhomarta-tokaeva">"скамейка запасных не очень длинная"</a>. В этой связи экономист Рахим Ошакбаев озвучил идею по созданию онлайн-платформы народного кадрового резерва - как альтернативного канала для независимого выдвижения кандидатов в госорганы (интервью на youtube-канале <a href="https://www.youtube.com/watch?v=QhxT03jPSBM&t=1137s">ГИПЕРБОРЕЙ</a>). Эта идея в качестве добровольной гражданской инициативы нашла отражение в реализации данного Сервиса.',
        '<b>About</b>\n\n' +
        'The Service or Service QYZMETKE - the service presented on this <a href="https://qyzmetke.com">web-site</a> and integrated components.\n\n' +
        'Service QYZMETKE enables expanding the top personnel reserve in public administration by covering the initiatives to hold public office aimed to implement proposals improving the quality of life and development of the country, while taking into account their public support. It has the functionality:\n' +
        '- registration of a candidate\'s profile;\n' +
        '- launching a campaign to support a candidate;\n' +
        '- secret voting for support/against a candidate;\n' +
        '- validation of the vote and voting results.\n\n' +
        'The service arose in response to the need to find solutions increasing social trust in public administration institutions. The President of Kazakhstan Kassym-Jomart Tokayev, in light of the tragic events of January 2022 in Kazakhstan and the subsequent change of the Government, pointed out that <a href="https://inbusiness.kz/ru/last/ministrom-mozhet-byt-kazhdyj-izmenenie-sistemy-ot-kasym-zhomarta-tokaeva">"the substitute bench is not very long"</a>. In this regard, the economist Rakhim Oshakbaev voiced the idea of creating an online platform of the civilian corps reserve - as an alternative channel for the independent nomination of candidates to state bodies (an interview on the youtube channel <a href="https://www.youtube.com/watch?v=QhxT03jPSBM&t=1137s">ГИПЕРБОРЕЙ</a>). This idea as a voluntary civic initiative is reflected in the implementation of this Service.',
        '<b>Сервис туралы</b>\n\n' +
        'Сервис немесе Сервис QYZMETKE – осы <a href="https://qyzmetke.com">веб-сайтта</a> және біріктірілген құрамдастарда ұсынылатын сервис.\n\n' +
        'Сервис QYZMETKE қоғамдық қолдауын өлшеуді ескере отырып, өмір сапасын жақсарту және елді дамыту жөніндегі ұсыныстарды іске асыру үшін мемлекеттік лауазымдарды иелену бастамаларын қамту арқылы мемлекеттік басқарудағы кадрлық резервті кеңейтуге мүмкіндік береді. Ол үшін келесідей функционалдығы бар:\n' +
        '- кандидаттың профилін тіркеу;\n' +
        '- кандидатты қолдау науқанын бастау;\n' +
        '- кандидатты қолдау/қарсы жасырын дауыс беру;\n' +
        '- дауысты және дауыс беру нәтижесін растау.\n\n' +
        'Сервис халықтың мемлекеттік басқару институттарына деген сенімін арттырудың шешімдерін табу қажеттілігіне жауап ретінде пайда болды. Қазақстан Президенті Қасым-Жомарт Тоқаев Қазақстандағы 2022 жылғы қаңтардағы қайғылы оқиғаларға және одан кейінгі Үкіметтің ауысуына байланысты <a href="https://inbusiness.kz/ru/last/ministrom-mozhet-byt-kazhdyj-izmenenie-sistemy-ot-kasym-zhomarta-tokaeva">“алмастыру орындығы өте ұзақ емес“</a> деп айтып кеткен болатын. Осыған байланысты экономист Рахым Ошақбаев мемлекеттік органдарға кандидаттарды тәуелсіз ұсынудың баламалы арнасы ретінде халықтық кадр резервінің онлайн платформасын құру идеясын айтқан болатын (<a href="https://www. youtube.com/watch?v=QhxT03jPSBM&t= 1137s">ГИПЕРБОРЕЙ</a> youtube арнасындағы сұхбат). Бұл идея ерікті азаматтық бастама ретінде осы Сервисті жүзеге асыруда көрініс тапты.'],
    FAQPage: ['<b>Часто задаваемые вопросы</b>\n\n' +
    'Как работает Сервис?\n' +
    '<a href="https://www.youtube.com/@jqservicetalentpool-yl9xp/videos">Ссылка на видео-инструкции</a>.\n' +
    'Алгоритм основного сценария:\n' +
    '1. Кандидат регистрируется в Сервисе через Telegram-бот <a href="https://t.me/qyzmetke_ratee_bot">qyzmetkeRateeBot</a> и переходит на <a href="https://qyzmetke.com/">веб-сайт</a> Сервиса для заполнения профиля.\n' +
    '2. Кандидат логинится на <a href="https://qyzmetke.com/">веб-сайте</a> Сервиса, заполняет свой профиль, устанавливает срок окончания кампании и инициирует запуск кампании.\n' +
    '3. Администратор Сервиса проверяет профиль и подтверждает разрешение на запуск кампании кандидата, либо фиксирует замечания.\n' +
    '4. Кандидат запускает кампанию через Telegram-бот <a href="https://t.me/qyzmetke_ratee_bot">qyzmetkeRateeBot</a>, получает и делится ссылкой на кампанию со своими контактами в приложении Telegram. В случае наличия замечаний администратора - предварительно их исправляет.\n' +
    '5. Рэйтер получает ссылку на кампанию в приложении Telegram, либо через общий Telegram-канал <a href="https://t.me/qyzmetke">qyzmetke</a>, и переходит в Telegram-бот <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a>.\n' +
    '6. Рэйтер в Telegram-бот <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a> получает первичную информацию о кампании, ссылку на профиль кандидата на веб-сайте и кнопки голосования в поддержку и против кандидата.\n' +
    '7. Рэйтер переходит по ссылке на веб-сайт, знакомится с профилем кандидата и нажимает кнопку перехода к голосованию.\n' +
    '8. Рэйтер переходит в Telegram-бот <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a>, голосует, получает данные ячейки и число валидации голоса, ссылку на файл Google Sheets с результатами голосования, а также ссылку на кампанию кандидата.\n' +
    '9. Рэйтер валидирует свой голос через функционал валидации на веб-сайте и сверяет с данными в файле Google Sheets.\n' +
    '10. Рэйтер делится ссылкой на кампанию со своими контактами в приложении Telegram. Процедура повторяется для следующего Рэйтера до наступления срока окончания кампании.\n' +
    'Список активных кампаний доступен в разделе <a href="https://qyzmetke.com/campaigns">“Кампании“</a> на веб-сайте Сервиса.\n' +
    'Список завершенных кампаний доступен в разделе <a href="https://qyzmetke.com/rankings">“Рэнкинг“</a> на веб-сайте Сервиса.\n\n' +
    'Кто такой кандидат и кто может им стать?\n' +
    'Кандидат – это претендент, зарегистрировавший свой профиль на <a href="https://qyzmetke.com/">веб-сайте</a> Сервиса и инициировавший кампанию по поддержке на занятие поста главы гос органа. Любой желающий может зарегистрироваться кандидатом при условии соблюдения <a href="https://qyzmetke.com/service-terms">Условий использования Сервиса</a>.\n\n' +
    'Гарантируете ли вы поступление на госслужбу? \n' +
    'Нет, основной посыл - донести полезные идеи в общественное поле. Возможно, вас заметят и пригласят на госслужбу для их реализации. В этом случае просим поделиться об этом на контакты <a href="https://qyzmetke.com/contacts">Сервиса</a>.\n\n' +
    'Почему кандидат подается только на руководящие позиции?\n' +
    'Идея в том, что кандидат должен выступить не исполнителем готовых решений, а управленцем со своим видением решений и проектным подходом к их внедрению. Кандидат целится занять пост на строго-ограниченный срок - сделать "обещанное" и сразу "уйти".\n\n' +
    'Почему кампания не дольше 1 месяца?\n' +
    'Кампания должна иметь четкий предметный фокус и “стрелять“. Актуальность выдвинутых предложений определяется скоростью отклика и уровнем поддержки. Нет смысла растягивать по времени неэффективную кампанию.\n\n' +
    'Почему такой ограниченный функционал?\n' +
    'Сервис является пилотным и функционирует в экспериментальном режиме. Требуется отладить ключевой функционал. В случае успеха возможно дальнейшее развитие функционала.\n\n' +
    'Зачем используется приложение Telegram? \n' +
    'Telegram используется как интегрируемый готовый компонент для “легкости“ покрытия целевой аудитории. Он массово признан, эффективен как независимый кибер-защищенный канал обмена сообщениями, API позволяет надстраивать функционал для автоматизированных ботов. Это помогает механизму Сервиса быть эффективным.\n\n' +
    'Как работает валидация голоса и почему ей можно доверять?\n' +
    'Для валидации голоса результаты голосования размещаются в публичном доступе в Google Sheets, а также для сверки имеется <a href="https://qyzmetke.com/validate">специальный раздел</a> на веб-сайте Сервиса. Заметьте! Сервис не запрашивает по какой кампании валидируется голос. Используется специально разработанный алгоритм обратной вычисляемости. Он независим от кампании и кандидата – достаточно предоставить координаты ячейки и число валидации. Этим гарантирует честность механизма голосования. С соблюдением тайны голосования.\n' +
    'Для <a href="https://qyzmetke.com/validate">валидации голоса<a/> на веб-сайте Сервиса – заполнить координаты ячейки на латинице и число валидации, и проверить поле “голос“ самой нижней заполненной строки. Данные в строках выше – определены путем обратного вычисления из валидируемого голоса. Легенда голоса по отношению к кандидату: 1 – ПОДДЕЖКА; 0 – ПРОТИВ. Поле “форк“ содержит координаты сестринской ячейки, которая имеет общую линию предков, указанных в поле “ячейка“ с меньшей глубиной. Таким образом, определив числа валидации по сестринским ячейкам из публичного файла валидации в Google Sheets, можно дополнительно сделать кросс-проверку на совпадение с голосами общих предков. Данный подход обеспечивает целостную прозрачность и валидируемость результатов голосования.\n\n' +
    'Вы гос проект? У вас есть полит повестка? Почему вам можно верить? Почему так мало информации о вас?\n' +
    'Сервис не является гос проектом, не имеет никакой полит повестки, и полностью нейтрален. По сути, это коллективный добровольный эффорт разных людей, специалистов от гражданского общества, которые ранее не знали друг друга и которых свели события “Января 2022“ - для того, чтобы подумать - “поштормить“ и сделать что-то социально полезное - технологически реализовать решения и создать инструменты, чтобы преобразовать накопившиеся и ищущие выход дисбалансы в конструктивное русло. Нет смысла громко заявлять о себе лично, основной посыл - чтобы заявляли о себе разные идеи и люди, которые имеют видение, желание, энергию, приверженность выступить в качестве кандидатов.\n\n' +
    'Можно ли вас поддержать? \n' +
    'Приветствуется поддержать саму инициативу, если она вам откликнулась, нет коммерческой подоплеки и желания на этом заработать, эта прежде всего социальная инициатива, соответственно, общественно-полезные цели стоят во главе.\n' +
    'Хотелось бы чтобы эта инициатива была поддержана обществом и передана в управление попечительскому совету - коллективному органу с прозрачным ведением деятельности и высокими этическими стандартами, в котором будут учтены интересы всех сторон и соблюдаться баланс интересов. \n' +
    'Пишите нам, если ваши идеи и предложения откликнутся, и мы сойдемся во взглядах - будет супер!',
        '<b>FAQ</b>\n\n' +
        'How does the Service work?\n' +
        '<a href="https://www.youtube.com/@jqservicetalentpool-yl9xp/videos">Link to video tutorials</a>.\n' +
        'Algorithm of the main scenario:\n' +
        '1. A candidate registers in the Service via Telegram-bot <a href="https://t.me/qyzmetke_ratee_bot">qyzmetkeRateeBot</a> and goes to the website of the <a href="https://qyzmetke.com/">Service</a> to fill out a profile.\n' +
        '2. A candidate logs on to the <a href="https://qyzmetke.com/">Service</a> website, fills out his or her profile, sets a campaign deadline, and initiates the launch of campaign.\n' +
        '3. A service administrator verifies the candidate\'s profile and confirms permission to launch the candidate\'s campaign, otherwise leaves comments.\n' +
        '4. A candidate launches a campaign via the Telegram bot <a href="https://t.me/qyzmetke_ratee_bot">qyzmetkeRateeBot</a>, receives and shares the campaign link with his/her contacts in the Telegram app. If there are comments from an administrator, a candidate corrects them beforehand.\n' +
        '5. A rater receives a link to the campaign in the Telegram app, or via the general Telegram channel <a href="https://t.me/qyzmetke">qyzmetke</a>, and goes to the Telegram bot <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a>.\n' +
        '6. A rater in the Telegram bot <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a> receives primary campaign information, a link to the candidate\'s profile on the website, and buttons to vote for support or against the candidate.\n' +
        '7. A rater clicks the link and goes to the website, gets acquainted with the candidate\'s profile, and clicks vote button.\n' +
        '8. A rater goes to the Telegram bot <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a>, votes, gets cell data and vote validation number, link to Google Sheets file with vote results, and link to candidate\'s campaign.\n' +
        '9. A rater validates his or her vote through the validation functionality on the website and checks against the data in the Google Sheets file.\n' +
        '10. A rater shares the campaign link with his/her contacts in the Telegram app. The procedure is repeated for the next Rater until the campaign deadline.\n' +
        'A list of active campaigns is available in the <a href="https://qyzmetke.com/campaigns">Campaigns</a> section of the Service website.\n' +
        'A list of completed campaigns is available in the <a href="https://qyzmetke.com/rankings">Ranking</a> section of the Service website.\n\n' +
        'Who is a candidate and who can become one?\n' +
        'A candidate is a candidate who has registered his/her profile on the <a href="https://qyzmetke.com/">Service</a> website and initiated a campaign to get into the position of the head of a public authority. Anyone can register as a candidate as long as they comply with the <a href="https://qyzmetke.com/service-terms">Terms of Service</a>.\n\n' +
        'Do you guarantee an admission to public office?\n' +
        'No, the main message is to bring useful ideas to the public. Perhaps you will get noticed and be invited to the public service to implement your ideas. If so, please share with us about it to the Service <a href="https://qyzmetke.com/contacts">contacts</a>.\n\n' +
        'Why should a candidate apply for top executive positions only?\n' +
        'The idea is that a candidate should not act as an implementer of ready-made solutions, but as a manager with a vision for solutions and a project-based approach to their implementation. The candidate aims to hold office for a strictly limited period of time - to do "what he promised" and then immediately "leave."\n\n' +
        'Why is a campaign duration no longer than 1 month?\n' +
        'A campaign must have a clear substantive focus and "shoot." The relevance of the proposals made is determined by the rate of response and the level of support. There\'s no point in stretching an ineffective campaign out over time.\n\n' +
        'Why such limited functionality?\n' +
        'The service is a pilot and is operating in the experimental mode. We need to debug the key functionality. If successful, it is possible to develop its functionality further.\n\n' +
        'Why use Telegram? \n' +
        'Telegram is used as an integrated ready-made component for "easy" coverage of target audiences. It is massively recognized, effective as an independent cyber-secure messaging channel, and the API allows building functionality for automated bots. This helps the Service mechanism to be effective.\n\n' +
        'How does vote validation work and why can it be trusted?\n' +
        'For vote validation, results are posted publicly in Google Sheets and there is a <a href="https://qyzmetke.com/validate">special section</a> on the Service\'s website for reconciliation. Notice! The service does not ask for which campaign the vote is validated. It uses a specially designed algorithm for reverse calculability. It is independent of the campaign and the candidate - you only need to provide the coordinates of the cell and the validation digit. This guarantees the integrity of the voting mechanism. The secrecy of the vote is respected.\n' +
        'To <a href="https://qyzmetke.com/validate">validate</a> the vote on the Service\'s website - fill in the cell coordinates in Latin characters and the validation digit, and check the "vote" field of the lowest filled in line. The data on the lines above are determined by back-calculating from the validated vote. Legend of the vote in relation to the candidate: 1 - SUPPORT; 0 - AGAINST. The "fork" field contains the coordinates of the sibling cell, which shares the ancestral line indicated in the "cell" field with less depth. Thus, by determining validation digits for sibling cells from the public validation file in Google Sheets, you can additionally cross-check for common ancestor votes. This approach ensures holistic transparency and validity of voting results.\n\n' +
        'Are you a government project? Do you have a political agenda? Why can you be trusted? Why is there so little information about you?\n' +
        'The service is not a government project, does not have any political agenda, and is completely neutral. In essence, it is a collective voluntary effort of different people, specialists from civil society, who did not know each other before, and who were brought together by the events of January 2022, in order to brainstorm, "stir things up" and do something socially useful. There is no point in making a strong personal statement about us, the main message is to bring front and center different ideas and people who have vision, desire, energy, commitment to act as candidates.\n\n' +
        'How to support you? \n' +
        'It is welcomed to support the initiative if it resonates with you, there are no commercial motives and a desire to make money out of it, this initiative is first of all a social one, respectively, socially useful goals are at the core.\n' +
        'It would be good if this initiative would be supported by society and would be delegated to some Board of Trustees - a collective body with a transparent conduct of its activities and high ethical standards, in which the interests of all parties would be taken into account and a balance of interests would be maintained.\n' +
        'Email to us, if your ideas and suggestions resonate and we come to alignment, that would be great!',
        '<b>Жиі қойылатын сұрақтар</b>\n\n' +
        'Сервис қалай істейді?\n' +
        '<a href="https://www.youtube.com/@jqservicetalentpool-yl9xp/videos">Бейне нұсқауларына сілтеме</a>.\n' +
        'Негізгі сценарий алгоритмі:\n' +
        '1. Кандидат <a href="https://t.me/qyzmetke_ratee_bot">qyzmetkeRateeBot</a> Telegram боты арқылы Сервиске тіркеледі және профильді толтыру үшін Сервистің <a href="https://qyzmetke.com/">веб-сайтына</a> өтеді.\n' +
        '2. Кандидат Сервис <a href="https://qyzmetke.com/">веб-сайтына</a> кіріп, өзінің профилін толтырады, науқанның аяқталу күнін белгілейді және науқанды бастауға бастамашы болады.\n' +
        '3. Сервис администраторі профильді тексереді және кандидаттың үгіт науқанын бастауға рұқсатын растайды немесе түзету ескертулерді жолдайды.\n' +
        '4. Кандидат <a href="https://t.me/qyzmetke_ratee_bot">qyzmetkeRateeBot</a> Telegram боты арқылы үгіт науқанын бастайды, Telegram қосымшасында өзінің контактілерімен науқанға сілтеме алады және бөліседі. Администратор тарапынан ескертулер болса, алдымен оны түзетеді.\n' +
        '5. Рейтер Telegram қосымшасында немесе жалпы qyzmetke Telegram <a href="https://t.me/qyzmetke">арнасы</a> арқылы науқанға сілтеме алады және <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a> Telegram ботына өтеді.\n' +
        '6. Рейтер <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a> Telegram ботында науқан туралы бастапқы ақпаратты, веб-сайттағы кандидаттың профиліне сілтемені, кандидатты қолдау және қарсы дауыс беру түймелерін алады.\n' +
        '7. Рэйтер веб-сайтқа сілтеме бойынша өтіп, кандидаттың профилімен танысады және дауыс беруге өту түймесін басады.\n' +
        '8. Рэйтер <a href="https://t.me/qyzmetke_rater_bot">qyzmetkeRaterBot</a> Telegram ботына кіріп, дауыс береді, ұяшық деректері мен дауысты валидациялау нөмірін, дауыс беру нәтижелері бар Google Sheets файлына сілтемені және кандидаттың науқанына сілтемені алады.\n' +
        '9. Рэйтер веб-сайттағы дауыс растау функциясы арқылы өз дауысын растайды және Google Sheets файлындағы деректермен салыстырады.\n' +
        '10. Рэйтер науқанға сілтемені Telegram контактілерімен бөліседі. Процедура келесі Рэйтерлер үшін науқанның соңына дейін қайталанады.\n' +
        'Белсенді науқандардың тізімі Сервис веб-сайтының <a href="https://qyzmetke.com/campaigns">“Науқандар“</a> бөлімінде қолжетімді.\n' +
        'Аяқталған науқандардың тізімі Қызмет веб-сайтындағы <a href="https://qyzmetke.com/rankings">“Рэнкинг“</a> бөлімінде қолжетімді.\n\n' +
        'Кандидат ол кім және кім болуы мүмкін?\n' +
        'Кандидат Сервис <a href="https://qyzmetke.com/">веб-сайтында</a> өз профилін тіркеген және мемлекеттік органның басшысы лауазымына орналасуға қолдау көрсету науқанын бастаған өтініш беруші болып табылады. <a href="https://qyzmetke.com/service-terms">Қызмет көрсету шарттарына</a> сәйкес кез келген адам кандидат ретінде тіркеле алады.\n\n' +
        'Мемлекеттік қызметке қабылдауға кепілдік бересіз бе? \n' +
        'Жоқ, ең бастысы – қоғам үшін пайдалы ойларды жеткізу. Мүмкін сіз назарға түсіп, ұсыныстарырыңызды жүзеге асыру үшін мемлекеттік қызметке шақырмақшы боларсыз. Бұл жағдайда оны Сервис <a href="https://qyzmetke.com/contacts">контактілеріне</a> бөлісуге сұраймыз.\n\n' +
        'Неліктен кандидат тек басшылық лауазымдарға өтініш беруі керек?\n' +
        'Мұның идеясы - кандидат дайын шешімдерді орындаушы ретінде емес, шешімдерге өзіндік көзқарасы бар және оларды жүзеге асыруға жобалық түрде менеджер ретінде әрекет етуі керек. Кандидат бұл лауазымда қатаң шектеулі мерзімде – “уәдесін“ орындап, бірден “кетуді“ көздейді.\n\n' +
        'Неліктен науқан 1 айдан аспайды?\n' +
        'Науқанның нақты тақырыбы және “атқаны“ болуы керек. Ұсынылған ұсыныстардың өзектілігі кері байланыстың жылдамдығымен және қолдау деңгейімен анықталады. Уақыт өте келе тиімсіз науқанды созудың еш мәні жоқ.\n\n' +
        'Неліктен мұндай шектеулі функционал?\n' +
        'Сервис пилоттық болып табылады және эксперименттік режимде жұмыс істейді. Біріншіден негізгі функционалын жөнді іске асыру қажет. Сәтті болса, функционалдылықты одан әрі дамытуға болады.\n\n' +
        'Неліктен Telegram пайдалануы керек? \n' +
        'Telegram мақсатты аудиторияны қамтудың “жеңілдігі“ үшін біріктірілген дайын құрамдас ретінде пайдаланылады. Ол жаппай танылған, тәуелсіз киберқауіпсіз хабар алмасу арнасы ретінде тиімді, API автоматтандырылған боттарға функционалдылықты қосуға мүмкіндік береді. Бұл Сервис тетігінің тиімді жұмыс істеуіне көмектеседі.\n\n' +
        'Дауысты тексеру қалай жұмыс істейді және неге оған сенуге болады?\n' +
        'Дауыс беруді растау үшін дауыс беру нәтижелері Google Sheets-те жалпыға қолжетімді жерде орналастырылады, сонымен қатар Сервистің веб-сайтында дауыс растауға арналған <a href="https://qyzmetke.com/validate">арнайы бөлім</a> бар. Назар аударыңыз! Қызмет қай науқан үшін дауыстың нақтыланып жатқанын сұрамайды. Арнайы әзірленген кері есептеу алгоритмі қолданылады. Ол науқанға және кандидатқа тәуелсіз - ұяшық координаттарын және валидация нөмірін көрсету жеткілікті. Бұл дауыс беру механизмінің тұтастығын қамтамасыз етеді. Дауыс беру құпиясын сақтай отырып.\n' +
        'Сервистің <a href="https://qyzmetke.com/validate">веб-сайтында</a> дауысты растау үшін – ұяшықтардың координаттарын латын тілінде және валидация нөмірін толтырып, ең төменгі толтырылған жолдың “дауыс“ өрісін белгілеңіз. Жоғарыдағы жолдардағы деректер расталған дауыс беруден кері есептеу арқылы анықталады. Кандидатқа қатысты дауыс беру аңызы: 1 - ҚОЛДАУ; 0 - ҚАРСЫ. “Форк“ өрісінде тереңдігі азырақ “ұяшық“ өрісінде көрсетілген ата-тегінің ортақ тегі бар туысты ұяшықтың координаттары бар. Осылайша, Google Sheets қолданбасындағы жалпы тексеру файлынан бауырлас ұяшықтардың валидация нөмірлерін анықтау арқылы сіз ортақ ата-бабалардың дауыстарымен сәйкестікті қосымша тексере аласыз. Бұл тәсіл біртұтас ашықтықты және дауыс беру нәтижелерін растауды қамтамасыз етеді.\n\n' +
        'Сіз мемлекеттік жобасыз ба? Сіздің саяси жоспарыңыз бар ма? Неліктен сізге сенуге болады? Неліктен сіз туралы ақпарат аз?\n' +
        'Сервис мемлекеттік жоба емес, ешқандай саяси жоспары жоқ және мүлдем бейтарап. Шындығында, бұл бұрын бір-бірін танымайтын және “Қаңтар 2022“ оқиғасы біріктірген әртүрлі адамдардың, азаматтық қоғам мамандарының – жан жақты ойланып қоғамдық пайдалы іспен айналысу үшін – ұжымдық ерікті әсері, шешімдерді технологиялық жүзеге асыру және жинақталған теңгерімсіздіктерді конструктивті арнаға айналдыру үшін құралдарды жасау. Өзімізді арнайы дауыстап жариялаудың мағынасы жоқ, бастысы - әртүрлі идеяларға және өзін кандидат ретінде жариялап шығуға көзқарасы, ниеті, энергиясы, міндеттемесі бар адамдарға арналған дауыстап жариялаудың мағынасы жоқ, бастысы - әртүрлі идеяларға және өзін кандидат ретінде жариялап шығуға көзқарасы, ниеті, энергиясы, міндеттемесі бар адамдарға арналған.\n\n' +
        'Сізге қолдау көрсетуге бола ма? \n' +
        'Бастаманың өзін қолдауға қуаныштымыз, егер ол сізге жақын болса, коммерциялық негізі жоқ және одан ақша табу ниетіміз жоқ, бұл ең алдымен әлеуметтік бастама, сәйкесінше, әлеуметтік пайдалылығы мақсаттардың ең басында.\n' +
        'Бұл бастама қоғам тарапынан қолдау тауып, барлық тараптардың мүдделерін ескеретін және теңгерімділікті сақтайтын қызметін ашық жүргізетін және жоғары этикалық нормаларды ұстанатын алқалы орган – қамқоршылық кеңестің басқаруына берілуін қалаймыз.\n' +
        'Бізге идеяларыңыз бен ұсыныстарыңызды жолданыз, егер олар ұстанымызға сай болса, біз ашықпыз - бұл керемет болар!'],
    UseTermsPage: ['Сервис, представленный на данном вебсайте и интегрированных компонентах (далее - Сервис), подлежит использованию на следующих условиях:\n\n' +
    'Соблюдение:\n' +
    '- законодательства Казахстана;\n' +
    '- требований Сервиса.\n\n' +
    'Согласие на:\n' +
    '- хранение персональных данных;\n' +
    '- сбор и обработку персональных данных.\n\n' +
    'Сервис использует минимально возможный набор персональных данных для выполнения целевого предназначения и обеспечения функционала.\n\n' +
    'Сервис функционирует в экспериментальном режиме, возможны сбои и корректирующие изменения интерфейсов, логики и условий использования для повышения качества функционирования.\n\n' +
    'Пользователь принимает Сервис в текущем состоянии “как есть“, без каких-либо гарантий и претензий на качество и последствия использования.\n\n' +
    'Предложения и замечания, иная коммуникация принимается по контактным данным, указанным в разделе “Контакты“. Обратная связь имеет высокий приоритет, но не гарантируется ввиду ограниченности ресурсов.',
        'The Service presented on this website and integrated components (hereinafter - Service) is subject to the following terms of use:\n\n' +
        'Compliance with:\n' +
        '- Legislation of Kazakhstan;\n' +
        '- Requirements of the Service.\n\n' +
        'Consent to:\n' +
        '- storing personal data;\n' +
        '- collection and processing of personal data.\n\n' +
        'The Service uses the minimum possible set of personal data for target purpose and functionality.\n\n' +
        'The Service operates in experimental mode, failures and corrective changes in the interfaces, logic and conditions of use are possible to improve the quality of functioning.\n\n' +
        'A user accepts the Service in its current state "as is", without any guarantees and claims for quality and consequences of use.\n\n' +
        'Suggestions and comments, other communication is accepted to the contact details specified in the section "Contacts". Feedback has a high priority, but is not guaranteed due to limited resources.',
        'Осы веб-сайтта ұсынылған қызмет және біріктірілген құрамдас бөліктер (бұдан әрі - Сервис) келесі шарттарда пайдаланылуы тиіс:\n\n' +
        'Сақтау:\n' +
        '- Қазақстанның заңнамасын;\n' +
        '- Сервис қойылатын талаптарды.\n\n' +
        'Келісім:\n' +
        '- жеке деректерді сақтауға;\n' +
        '- жеке деректерді жинауға және өңдеуге.\n\n' +
        'Сервис өзінің мақсатын орындау және функционалдылықты қамтамасыз ету үшін жеке деректердің ең аз мүмкін жинағын пайдаланады.\n\n' +
        'Сервис эксперименттік режимде жұмыс істейді, жұмыс сапасын жақсарту үшін интерфейстердегі, логикадағы және пайдалану шарттарындағы ақаулар мен түзетуші өзгерістер мүмкін.\n\n' +
        'Пайдаланушы Сервисті сапасы мен пайдалану салдарына қатысты ешқандай кепілдіктер мен шағымдарсыз “қазіргі күйінде“ қабылдайды.\n\n' +
        'Ұсыныстар мен ескертулер, басқа хабарламалар “Байланысу“ бөлімінде көрсетілген байланыс деректері арқылы қабылданады. Кері байланыс жоғары басымдыққа ие, бірақ шектеулі ресурстарға байланысты кепілдік берілмейді.'],
    errorValidate1: ['неверный ввод номера листа', 'wrong sheet input', 'парақ нөмірін қате енгізу'],
    errorValidate2: ['превышен максимальный номер листа', 'max sheet number exceeded', 'максималды парақ саны асып кетті'],
    errorValidate3: ['неверный ввод номера ячейки', 'wrong cell input', 'ұяшық нөмірін қате енгізу'],
    errorValidate4: ['номер строки должен быть > 0', 'row number must be > 0', 'жол нөмірі > 0 болуы керек'],
    errorValidate5: ['превышен максимальный размер строки листа', 'sheet max row size exceeded', 'парақтың жолы максималды өлшемінен асып кетті'],
    errorValidate6: ['превышен максимальный размер столбца листа', 'sheet max column size exceeded', 'парақтың бағаны максималды өлшемінен асып кетті'],
    errorValidate7: ['неправильный ввод слота', 'wrong slot input', 'слотты қате енгізу'],
    errorValidate8: ['неверный ввод числа валидации', 'wrong validation digit input', 'валидация нөмірін қате енгізу'],
    languageModalOwner: ['Заполни на других языках', 'Fill it out in other languages', 'Басқа тілдерде толтыру'],
    languageModalGuest: ['Если пусто, посмотри на других языках', 'If empty, check in other languages', 'Толтырылмаған болса, басқа тілдерде тексер'],
}