import axios from "../../api/index"
import { mainSlice } from "../slices/mainSlice"

export const fetchAuth = (props) => {
    return async (dispatch) => {
        try {
            /*dispatch(mainSlice.actions.fetching())*/
            const res = await axios.post(`/ratees/sessions`, props);
            localStorage.setItem('tokenAccess', res.data.data.access)
            localStorage.setItem('tokenRefresh', res.data.data.refresh)
            localStorage.setItem('ratee', res.data.data.ratee.toString())
            return res.data.data
        } catch (e) {
            console.error(e)
        }
    }
}

export const fetchLogOut = () => {
    return async () => {
        try {
            localStorage.removeItem('tokenAccess');
            localStorage.removeItem('tokenRefresh');
            localStorage.removeItem('ratee');
            localStorage.removeItem('roles');
        } catch (e) {
            console.error(e)
        }
    }
}
