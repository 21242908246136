import React, {useEffect, useState} from "react";
import GuestCabinet from "./GuestCabinet";
import OwnerCabinet from "./OwnerCabinet";
import NoProfile from "../NoProfile";

const CabinetComponent = (props) => {
    const {getTranslate, language, candidate, isAdmin} = props;
    const [isOwner, setIsOwner] = useState(false);
    const loggedUserId = localStorage.getItem('ratee');
    const TITLE = language === 'EN' ? 'title_en' : language === 'QZ' ? 'title_qz' : 'title_ru';
    const LAST_NAME = language === 'EN' ? 'last_name_en' : language === 'QZ' ? 'last_name_qz' : 'last_name_ru';
    const FIRST_NAME = language === 'EN' ? 'first_name_en' : language === 'QZ' ? 'first_name_qz' : 'first_name_ru';
    const PROPOSALS_TITLE = language === 'EN' ? 'proposals_en' : language === 'QZ' ? 'proposals_qz' : 'proposals_ru';
    const PROMO_MESSAGE = language === 'EN' ? 'promo_message_en' : language === 'QZ' ? 'promo_message_qz' : 'promo_message_ru';
    useEffect(() => {
        setIsOwner( loggedUserId && candidate?.ratee ? candidate?.ratee === parseInt(loggedUserId) : false);
    }, [loggedUserId, candidate?.ratee]);
    const webV = window.innerWidth > 1024;

    return (
        <div className="cabinet container row" style={{marginTop: '100px'}}>
            {candidate ? !isOwner ?
                <GuestCabinet
                    isAdmin={isAdmin}
                    language={language}
                    candidate={candidate}
                    webV={webV}
                    getTranslate={getTranslate}
                    TITLE={TITLE}
                    LAST_NAME={LAST_NAME}
                    FIRST_NAME={FIRST_NAME}
                    PROPOSALS_TITLE={PROPOSALS_TITLE}
                    PROMO_MESSAGE={PROMO_MESSAGE}
                />
                :
                <OwnerCabinet
                    language={language}
                    candidate={candidate}
                    webV={webV}
                    getTranslate={getTranslate}
                    TITLE={TITLE}
                    LAST_NAME={LAST_NAME}
                    FIRST_NAME={FIRST_NAME}
                    PROPOSALS_TITLE={PROPOSALS_TITLE}
                    PROMO_MESSAGE={PROMO_MESSAGE}
                />
                :
                <NoProfile getTranslate={getTranslate} />
            }
        </div>
    )
}

export default CabinetComponent;