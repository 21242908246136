import React, {useEffect} from "react";

const FAQPage = (props) => {
    const {getTranslate, language} = props;
    useEffect(() => {
        document.getElementById('msg-div').innerHTML = getTranslate('FAQPage');
    }, [language])

    return(
        <div style={{marginTop: '100px', padding: '24px', whiteSpace: 'pre-wrap'}}>
            <div id="msg-div"></div>
        </div>
    )
}

export default FAQPage;