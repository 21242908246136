import React from "react";
import qwe from "../../assets/img/qweqwe.jpg";
import ewq from "../../assets/img/ewq.jpg";
import challengedAvatar1 from "../../assets/img/frame_person_FILL1_wght200_GRAD200_opsz48.svg"; //
import person_avatar from "../../assets/img/person_FILL1_wght200_GRAD200_opsz48.svg";
import useAnalyticsEventTracker from "../../googleAnalytics/useAnalyticsEventTracker";

const CarouseItem = (props) => {
    const {mobV, getTranslate} = props;
    const {ratee, seek_position} = props.campaign;
    const rateeAvatar = ratee.image || person_avatar;
    const moreGA = useAnalyticsEventTracker('Contact us', 'click_pod', 'header');

    return (
        <>
            {!mobV ?
                <div className="voting-web">
                    <div className="voting-web-select">
                        <div className="voting-web-select-challenged">
                            <h3>{getTranslate('boss')}</h3>
                            <h4>{seek_position?.title_ru}</h4>

                            <div>
                                <a href="https://dialog.egov.kz/blogs">
                                    <div className="flag-blur"></div>
                                    <img src={challengedAvatar1} alt="active-person"/>
                                </a>
                            </div>
                        </div>
                        <div className="voting-web-select-ratee">
                            <h3>{getTranslate('candidate')}</h3>
                            <h4>{getTranslate('tagline')}</h4>
                            <div>
                                <div className="blur"></div>
                                <img src={rateeAvatar} alt="voting-person"/>
                            </div>
                        </div>
                    </div>
                    <a onClick={() => moreGA()} href={`/profiles/${ratee.ratee}`}
                       className="btn btn-custom btn-lg my-5">{getTranslate('more')}</a>
                </div>
                :
                <div className="voting-mob">
                    <div className="voting-mob-select">
                        <div className="voting-mob-select-challenged">
                            <p>{getTranslate('boss')}</p>
                            <b>{seek_position?.title_ru}</b>
                            <div style={{marginTop: '8px'}}>
                                <div className="flag-blur"></div>
                                <img src={challengedAvatar1} alt="active-person"/>
                            </div>
                        </div>
                        <div className="voting-mob-select-ratee">
                            <p>{getTranslate('candidate')}</p>
                            <b>{getTranslate('tagline')}</b>
                            <div style={{marginTop: '8px'}}>
                                <div className="blur"></div>
                                <img src={rateeAvatar} alt="voting-person"/>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <a onClick={() => moreGA()} href={`/profiles/${ratee.ratee}`}
                           className="btn btn-custom btn-lg mt-4">{getTranslate('more')}</a>
                    </div>
                </div>
            }
        </>
    )
}

export default CarouseItem