import {useEffect, useState} from "react";
import Voting from "../../components/Voting";
import axios from "../../api";
import Loader from "../../components/Loader";

const MainPage = (props) => {
    const {getTranslate} = props;
    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        const Promise = axios.post('campaigns/ongoing/?limit=10&offset=1', {is_admin: false});
        Promise.then(response => {
            setCampaigns(response?.data?.data?.campaigns);
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }).catch(e => {
            console.error(e);
            setIsLoading(false);
        })
    }, [])

    return (
        <>
            {isLoading ? <Loader/> :
                <>
                    <Voting getTranslate={getTranslate} campaigns={campaigns} />
                </>
            }

        </>
    );
};

export default MainPage;
