import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import ValidateTable from "../../components/Validate/ValidateTable";
import {validate} from "../../components/Validate/script";

const ValidatePage = (props) => {
    const {getTranslate, isAdmin} = props;
    const [result, setResult] = useState({});
    const [sheet, setSheet] = useState(0);
    const [slot, setSlot] = useState('');
    const [digitNumber, setDigitNumber] = useState(0);
    const webV = window.innerWidth > 1024;

    useEffect(() => {
        sheet !== 0 && slot !== '' && digitNumber !== 0 &&
        setResult(validate(sheet, slot, digitNumber));
    }, [sheet, slot, digitNumber]);

    return (
        <div className="mx-4" style={{marginTop: '100px'}}>
            <div><h3>{getTranslate('validateVote')}</h3></div>
            <div className="validate">
                <div className="validate-form">
                    <div className="validate-form-head">
                        <h4>{getTranslate('validateSlotLabel')}</h4>
                        <p>{typeof result === "string" && getTranslate(result)}</p></div>
                    <div className="validate-form-1">
                        <Form.Group className="mb-3 d-flex" controlId="formBasicPassword">
                            <Form.Label>Sheet</Form.Label>
                            <Form.Control className="input" onChange={(e) => setSheet(Number(e.target.value))}
                                          type="number"/>
                        </Form.Group>
                        <Form.Group className={`mb-3 ${!webV ? 'ms-3' : ''} d-flex`} controlId="formBasicPassword">
                            <Form.Label>{getTranslate('validateSlot')}</Form.Label>
                            <Form.Control className="input" onChange={(e) => setSlot(e.target.value)} type="text"/>
                        </Form.Group>
                    </div>
                    <div className="validate-form-2">
                        <Form.Group className="mb-3 d-flex" controlId="formBasicPassword">
                            <Form.Label>{props.getTranslate('validateNumber')}</Form.Label>
                            <Form.Control className="input" onChange={(e) => setDigitNumber(Number(e.target.value))}
                                          type="number"/>
                        </Form.Group>
                    </div>
                    <div className={webV ? "validate-form-table" : "validate-form-table-mob"}>
                        <ValidateTable result={result} getTranslate={getTranslate}/>
                    </div>
                </div>
                <div className={"validate-info"}>
                    <div><b>{getTranslate('validateInfoFirst')}<a href="/faq"> {getTranslate('here').toLowerCase()}</a></b>
                    </div>
                    <br/>
                    <div>
                        <b>
                            <span style={{color: 'red'}}>{getTranslate('forValidateVote')} </span>
                            - {getTranslate('validateInfoSecond')}
                        </b>
                    </div>
                    <br/>
                    <div><b>
                        {getTranslate('validateInfoThird')}
                        <br/>
                        0 - {getTranslate('against')}
                        <br/>
                        1 - {getTranslate('for')}
                    </b></div>
                </div>
            </div>
        </div>
    )
}

export default ValidatePage;